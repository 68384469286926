import React from "react";

const HandbookIcon = () => {
  return (
    <svg
      id="meat"
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      viewBox="0 0 15.682 15.683"
    >
      <path
        id="Path_102"
        data-name="Path 102"
        d="M385.626,193.19a.459.459,0,1,0,.459.459A.459.459,0,0,0,385.626,193.19Z"
        transform="translate(-373.37 -187.275)"
        fill="#fff"
      />
      <path
        id="Path_103"
        data-name="Path 103"
        d="M15.7,9.243c-.536-3.49-2.386-6.148-5.853-6.819A4.724,4.724,0,0,0,8.435.746,4.639,4.639,0,0,0,4.058.366a.459.459,0,0,0,.053.886,2.5,2.5,0,0,1,1.8,1.259A5.779,5.779,0,0,0,3.775,3.4C.526,5.544-.4,8.452.2,10.893h0c.7,2.859,3.637,4.649,3.761,4.724a.46.46,0,0,0,.24.066A2.05,2.05,0,0,0,5.7,15a2.994,2.994,0,0,0,.419-2.583.46.46,0,0,0-.636-.333,4.326,4.326,0,0,1-1.7.3,3.063,3.063,0,0,1-2.613-1.6,4.956,4.956,0,0,1,.994-1.228,2.853,2.853,0,0,1,3.091-.541A22.831,22.831,0,0,0,8.866,10.04a11.318,11.318,0,0,0,6.409-.235.67.67,0,0,0,.36-.244.459.459,0,0,0,.067-.319ZM5.285,13.11A1.782,1.782,0,0,1,5,14.393a1.08,1.08,0,0,1-.687.363A9.752,9.752,0,0,1,2.338,13,4.6,4.6,0,0,0,5.285,13.11Zm4.9-3.781a5.164,5.164,0,0,1-.64-3.6,3.318,3.318,0,0,1,1.6-1.934,6.74,6.74,0,0,1,3.589,5.237A9.578,9.578,0,0,1,10.182,9.329ZM8.689,2.28a10.1,10.1,0,0,0-1.828.066A3.367,3.367,0,0,0,5.7.934,3.188,3.188,0,0,1,8.689,2.28Zm-3.1,5.873A3.881,3.881,0,0,0,.957,9.475c.013-1.373.568-3.49,3.325-5.31a4.748,4.748,0,0,1,.891-.478,8.207,8.207,0,0,1,2.282-.478,8.164,8.164,0,0,1,2.623.207c-1.826,1.441-1.926,3.591-1,5.726a22.528,22.528,0,0,1-3.484-.99Z"
        transform="translate(-0.026 0)"
        fill="#fff"
      />
    </svg>
  );
};

export default HandbookIcon;
