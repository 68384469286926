import React, {useEffect} from 'react';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router-dom';
import { Button, Grid } from '@material-ui/core';

import { useStyles } from './terms-and-conditions';

const RefundPolicy=(props)=> {
    const classes = useStyles();
    useEffect(() => {
        if(window.pageYOffset>75){
          document.body.scrollTop=0;
          document.documentElement.scrollTop=0;
        }
        return () => {
          
        }
      }, [])
      const handleClick=()=>{
        props.history.push('/reach-out');
      }
    return (
        <Grid item xs={10} md={9} className={classes.root}>
            <Grid container direction="column">
            <Typography variant="h3" gutterBottom className={classes.terms_title}>Refund policy</Typography>
            
            <Typography variant="body1" className={classes.terms_text} gutterBottom>Since the Mobile Application offers non-tangible, irrevocable goods we do not provide refunds after the product is purchased, which you acknowledge prior to purchasing any product in the Mobile Application. Please make sure that you've carefully read service description before making a purchase.</Typography>
            
            <Typography variant="h4" className={classes.terms_headings} gutterBottom>Contacting us</Typography>
            <Typography variant="body1" className={classes.terms_text} gutterBottom>If you would like to contact us concerning any matter relating to this Refund Policy, you may do so via the contact form</Typography>
            <Button variant="contained" className={classes.reachout} onClick={handleClick}>Reach out</Button>
        </Grid>
    </Grid>
    );
}
export default withRouter(RefundPolicy);;