import React, { useEffect, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import MuiPhoneNumber from "material-ui-phone-number";

import {
  Button,
  Grid,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import firebase from "../firebase";

import { country_list, subject_list } from "../utils/country-details";
import ReCAPTCHA from "react-google-recaptcha";
import ReachOutConfirm from "../components/ReachOutConfirm";
import AppSelectCountry from "../components/AppSelectCountry";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "white",
    },
    "& label": {
      color: "rgba(255,255,255,0.8)",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
        border: "1px solid",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
      },
    },
  },
})(TextField);
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2rem",
    backgroundColor: "rgba(0,0,0,0.2)",
    marginTop: "2rem",
    borderRadius: "2rem",
  },
  contact_us_form: {
    marginTop: "3rem",
    marginBottom: "11rem",
    zIndex: "10",
  },
  contact_email: {
    marginTop: "1rem",
    marginBottom: "2rem",
    width: "100%",
  },
  description: {
    width: "100%",
    marginBottom: "2rem",
  },
  contact_heading: {
    width: "100%",
    textTransform: "uppercase",
    // backgroundColor: 'white',
    padding: "1rem",
    color: "white",
    textAlign: "center",
    fontFamily: "Poppins",
    fontWeight: "500",
  },
  contact_us_submit: {
    marginBottom: "2rem",
    color: "white",
    borderColor: "white",
    transition: "all .4s ease-in-out",
    "&:hover": {
      backgroundColor: "#FFF",
      color: "#179699",
      borderColor: "white",
    },
    [theme.breakpoints.down("xs")]: {
      backgroundColor: "#FFF",
      color: "#179699",
    },
  },
  radio_contact_type: {
    color: "white",
    fontFamily: "Poppins",
  },
  radio_reachout: {
    display: "flex",
  },
  select_icon: {
    fill: "white",
  },
  radio_label_text: {
    color: "white",
  },
  captcha_resize: {
    [theme.breakpoints.down("xs")]: {
      transform: "scale(0.73)",
      transformOrigin: "0 0",
    },
  },
}));

const initFormData = {
  firstName: "",
  lastName: "",
  businessName: "",
  websiteName: "",
  value: "Business",
  purpose: "Informational",
  email: "",
  country: "",
  subject: "",
  details: "",
  purposeDetails: "",
  PhoneNumber1: "",
  PhoneNumber2: "",
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ReachOut = (props) => {
  const classes = useStyles();
  const [value, setValue] = useState("Business");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [websiteName, setWebsiteName] = useState("");
  const [purpose, setPurpose] = useState("Informational");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [subject, setSubject] = useState("");
  const [details, setDetails] = useState("");
  const [purposeDetails, setPurposeDetails] = useState("");
  const [PhoneNumber1, setNumber1] = useState("");
  const [PhoneNumber2, setNumber2] = useState("");
  const [submit, setSubmit] = useState(false);
  const [error, setError] = useState(false);
  const [captcha, setCaptcha] = useState("");
  const [snackOpen, setSnackOpen] = useState(false);
  const [formData, setFormData] = useState(initFormData);

  useEffect(() => {
    if (window.pageYOffset > 75) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
    return () => {};
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (captcha === "") {
      alert("Please check the recaptcha");
      return;
    }
    if (PhoneNumber1 === "") {
      setError(true);
      return;
    }

    setFormData({
      value,
      firstName,
      lastName,
      businessName,
      websiteName,
      purpose,
      email,
      country,
      subject,
      details,
      purposeDetails,
      PhoneNumber1,
      PhoneNumber2,
    });
    setSubmit(true);
  };

  const handleClose = () => {
    setSubmit(false);
  };

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
  };

  const submitAndSend = async () => {
    const mail = await firebase
      .firestore()
      .collection("mail")
      .add({
        to: "info@slidesme.com",
        message: {
          subject: `Fashfoosh ${subject} ${purpose}`,
          html: `<div style="color:black"><h3 style='display:inline-block;'>Contacting as:</h3>&nbsp;<h4 style='display:inline-block;'>${value}</h4><br/>
          <h3 style='display:inline-block;'>First Name:</h3>&nbsp;<h4 style='display:inline-block;'>${firstName}</h4><br/>
          <h3 style='display:inline-block;'>Last Name:</h3>&nbsp;<h4 style='display:inline-block;'>${lastName}</h4><br/>
          <h3 style='display:inline-block;'>Business Name:</h3>&nbsp;<h4 style='display:inline-block;'>${businessName}</h4><br/>
          <h3 style='display:inline-block;'>Website Name:</h3>&nbsp;<h4 style='display:inline-block;'>${websiteName}</h4><br/>
          <h3 style='display:inline-block;'>Email:</h3>&nbsp;<h4 style='display:inline-block;'>${email}</h4><br/>
          <h3 style='display:inline-block;'>Phone Number:</h3>&nbsp;<h4 style='display:inline-block;'>${PhoneNumber1}</h4><br/>
          <h3 style='display:inline-block;'>Alternate Number:</h3>&nbsp;<h4 style='display:inline-block;'>${PhoneNumber2}</h4><br/>
          <h3 style='display:inline-block;'>Country:</h3>&nbsp;<h4 style='display:inline-block;'>${country}</h4><br/>
          <h3 style='display:inline-block;'>Details:</h3>&nbsp;<h4 style='display:inline-block;'>${details}</h4><br/>
          <h3 style='display:inline-block;'>Purpose:</h3>&nbsp;<h4 style='display:inline-block;'>${purpose}</h4><br/>
          <h3 style='display:inline-block;'>Purpose Details:</h3>&nbsp;<h4 style='display:inline-block;'>${purposeDetails}</h4></div>
            `,
        },
      });
    if (mail) {
      setValue("Business");
      setFirstName("");
      setLastName("");
      setBusinessName("");
      setWebsiteName("");
      setPurpose("Informational");
      setEmail("");
      setCountry("");
      setSubject("");
      setDetails("");
      setPurposeDetails("");
      setNumber1("");
      setNumber2("");
      setSnackOpen(true);
      setSubmit(false);
    }
  };
  const handleChange = (event) => {
    event.preventDefault();
    setValue(event.target.value);
  };
  const onCountryChange = (event, value) => {
    if (value) setCountry(value.label);
  };

  const onCaptchaChange = (value) => {
    setCaptcha(value);
  };
  return (
    <>
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.contact_us_form}
      >
        <Grid item xs={10} md={8} className={classes.root}>
          <form onSubmit={handleSubmit}>
            <Grid container justify="center" alignItems="center">
              <Typography variant="h4" className={classes.contact_heading}>
                Reach Out
              </Typography>
              <Grid item xs={9}>
                <FormControl
                  component="fieldset"
                  variant="outlined"
                  className={classes.contact_email}
                >
                  <Typography
                    variant="h6"
                    className={classes.radio_contact_type}
                    gutterBottom
                  >
                    Are you contacting us on as
                  </Typography>
                  <RadioGroup
                    name="contact-type"
                    value={value}
                    onChange={handleChange}
                    row
                  >
                    <FormControlLabel
                      value="Business"
                      control={<Radio style={{ color: "white" }} />}
                      label="Business"
                      className={classes.radio_label_text}
                    />
                    <FormControlLabel
                      value="Individual"
                      control={<Radio style={{ color: "white" }} />}
                      label="Individual"
                      className={classes.radio_label_text}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={9}>
                <CssTextField
                  label="First Name"
                  variant="outlined"
                  type="text"
                  value={firstName}
                  id="firstName"
                  className={classes.contact_email}
                  onChange={({ target: { value } }) => {
                    setFirstName(value);
                  }}
                  required
                />
              </Grid>
              <Grid item xs={9}>
                <CssTextField
                  label="Last Name"
                  variant="outlined"
                  type="text"
                  value={lastName}
                  id="lastName"
                  className={classes.contact_email}
                  onChange={({ target: { value } }) => {
                    setLastName(value);
                  }}
                  required
                />
              </Grid>
              <Grid item xs={9}>
                <CssTextField
                  label="Name of the business"
                  variant="outlined"
                  type="text"
                  value={businessName}
                  id="businessName"
                  className={classes.contact_email}
                  onChange={({ target: { value } }) => {
                    setBusinessName(value);
                  }}
                  required={value == "Business" ? true : false}
                />
              </Grid>
              <Grid item xs={9}>
                <CssTextField
                  label="Website of the business"
                  variant="outlined"
                  type="text"
                  value={websiteName}
                  id="websiteName"
                  className={classes.contact_email}
                  onChange={({ target: { value } }) => {
                    setWebsiteName(value);
                  }}
                  required={value == "Business" ? true : false}
                />
              </Grid>
              <Grid item xs={9}>
                <CssTextField
                  label="Email"
                  variant="outlined"
                  type="email"
                  value={email}
                  id="email"
                  className={classes.contact_email}
                  onChange={({ target: { value } }) => {
                    setEmail(value);
                  }}
                  required
                />
              </Grid>
              <Grid item xs={9}>
                <MuiPhoneNumber
                  label="Enter your phone number"
                  variant="outlined"
                  value={PhoneNumber1}
                  className={classes.contact_email}
                  onChange={(value) => {
                    setNumber1(value);
                  }}
                  defaultCountry={"bh"}
                  error={error}
                  helperText={error ? "Enter a Number" : ""}
                  required
                />
              </Grid>
              <Grid item xs={9}>
                <MuiPhoneNumber
                  label="Enter another phone number"
                  variant="outlined"
                  value={PhoneNumber2}
                  className={classes.contact_email}
                  onChange={(value) => {
                    setNumber2(value);
                  }}
                  defaultCountry={"bh"}
                />
              </Grid>
              <Grid item xs={9}>
                <div className={classes.contact_email}>
                  <AppSelectCountry onCountryChange={onCountryChange} />
                </div>
              </Grid>
              <Grid item xs={9}>
                <Autocomplete
                  id="subject-select"
                  className={classes.contact_email}
                  options={subject_list}
                  autoHighlight
                  onChange={(event, value) => {
                    setSubject(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Subject"
                      variant="outlined"
                      required
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={9}>
                <CssTextField
                  label="Details"
                  value={details}
                  variant="outlined"
                  multiline
                  rows={2}
                  rowsMax={4}
                  id="details"
                  inputProps={{ maxlength: 2000 }}
                  className={classes.description}
                  onChange={({ target: { value } }) => {
                    setDetails(value);
                  }}
                  required
                />
              </Grid>
              <Grid item xs={9}>
                <FormControl
                  component="fieldset"
                  variant="outlined"
                  className={classes.contact_email}
                >
                  <Typography
                    variant="h6"
                    className={classes.radio_contact_type}
                    gutterBottom
                  >
                    Purpose
                  </Typography>
                  <RadioGroup
                    name="purpose-type"
                    value={purpose}
                    onChange={(event) => {
                      event.preventDefault();
                      setPurpose(event.target.value);
                    }}
                    row
                  >
                    <FormControlLabel
                      value="Informational"
                      control={<Radio style={{ color: "white" }} />}
                      label="Informational"
                      className={classes.radio_label_text}
                    />
                    <FormControlLabel
                      value="ActionRequired"
                      control={<Radio style={{ color: "white" }} />}
                      label="Action Required"
                      className={classes.radio_label_text}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {purpose === "ActionRequired" ? (
                <Grid item xs={9} className={classes.contact_email}>
                  <CssTextField
                    label="Clarify the impact and justify your position"
                    value={purposeDetails}
                    variant="outlined"
                    multiline
                    rows={2}
                    rowsMax={4}
                    id="purpose-details"
                    className={classes.description}
                    onChange={({ target: { value } }) => {
                      setPurposeDetails(value);
                    }}
                    required
                  />
                </Grid>
              ) : (
                ""
              )}
              <Grid item xs={9} className={classes.contact_email}>
                <ReCAPTCHA
                  sitekey="6LfrcNoZAAAAAG8GaRWgAZU5PR9ZTobVhZXGkoNl"
                  onChange={onCaptchaChange}
                  className={classes.captcha_resize}
                />
              </Grid>
              <Grid item xs={9} className={classes.contact_email}>
                <Button
                  type="submit"
                  variant="outlined"
                  color="secondary"
                  className={classes.contact_us_submit}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
      {submit ? (
        <ReachOutConfirm
          handleClose={handleClose}
          formData={formData}
          submitAndSend={submitAndSend}
        />
      ) : (
        ""
      )}
      <Snackbar
        open={snackOpen}
        autoHideDuration={2000}
        onClose={handleSnackClose}
      >
        <Alert onClose={handleSnackClose} severity="success">
          Mail sent successfully
        </Alert>
      </Snackbar>
    </>
  );
};

export default ReachOut;
