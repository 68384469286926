import React from "react";

const Bubbles = ({ height = "177", width = "177", top, left, right }) => {
  return (
    <div
      style={{
        position: "absolute",
        zIndex: "0",
        top: top,
        left: left,
        right: right,
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={width}
        height={height}
        viewBox="0 0 177 177"
      >
        <defs>
          <clipPath id="clip-path">
            <rect width="177" height="177" fill="none" />
          </clipPath>
          <linearGradient
            id="linear-gradient"
            x1="0.5"
            x2="0.5"
            y2="1"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stop-color="#ddf5fd" />
            <stop offset="1" stop-color="#77dfff" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-2"
            x1="0.15"
            y1="0.121"
            x2="0.874"
            y2="0.815"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stop-color="#a8dfff" />
            <stop offset="1" stop-color="#3698ce" />
          </linearGradient>
        </defs>
        <g
          id="Repeat_Grid_3"
          data-name="Repeat Grid 3"
          clip-path="url(#clip-path)"
        >
          <g transform="translate(631 1305)">
            <g
              id="bubble_ring"
              data-name="bubble ring"
              transform="translate(-869.631 -1489)"
              opacity="0.2"
            >
              <path
                id="Subtraction_2"
                data-name="Subtraction 2"
                d="M88.5,177A88.5,88.5,0,0,1,25.921,25.921,88.5,88.5,0,1,1,151.079,151.079,87.921,87.921,0,0,1,88.5,177Zm.348-163.116a74.964,74.964,0,1,0,74.964,74.964A75.051,75.051,0,0,0,88.848,13.884Z"
                transform="translate(238.631 184)"
                fill="url(#linear-gradient)"
              />
              <path
                id="Subtraction_1"
                data-name="Subtraction 1"
                d="M79,162a77.513,77.513,0,0,1-55.861-23.725,82.456,82.456,0,0,1,0-114.55,77.627,77.627,0,0,1,111.723,0,82.456,82.456,0,0,1,0,114.55A77.513,77.513,0,0,1,79,162Zm.343-141.751c-33.522,0-60.794,27.723-60.794,61.8s27.272,61.8,60.794,61.8,60.794-27.723,60.794-61.8S112.865,20.249,79.343,20.249Z"
                transform="translate(248.631 192)"
                fill="url(#linear-gradient-2)"
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default Bubbles;
