import React from "react";
import AcceptablePolicy from "./acceptable-policy";
import CookiePolicy from "./cookie-policy";
import TermsAndConditions from "./terms-and-conditions";
import Disclaimer from "./disclaimer";
import PrivacyPolicy from "./privacy-policy";
import RefundPolicy from "./refund-policy";

const AllTermsAndCondition = () => {
  return (
    <>
      <TermsAndConditions />
      <AcceptablePolicy />
      <CookiePolicy />
      <Disclaimer />
      <PrivacyPolicy />
      <RefundPolicy />
    </>
  );
};

export default AllTermsAndCondition;
