import React, { useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { withRouter } from "react-router-dom";
import { Button, Grid } from "@material-ui/core";

import { useStyles } from "./terms-and-conditions";

const CookiePolicy = (props) => {
  const classes = useStyles();
  useEffect(() => {
    if (window.pageYOffset > 75) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
    return () => {};
  }, []);
  const handleClick = () => {
    props.history.push("/reach-out");
  };
  return (
    <Grid item xs={10} md={9} className={classes.root}>
      <Grid container direction="column">
        <Typography variant="h3" gutterBottom className={classes.terms_title}>
          Cookie policy
        </Typography>

        <Typography variant="body1" className={classes.terms_text} gutterBottom>
          This cookie policy ("Policy") describes what cookies are and how and
          they're being used by the FASHFOOSH.com website ("Website" or
          "Service") and any of its related products and services (collectively,
          "Services"). This Policy is a legally binding agreement between you
          ("User", "you" or "your") and SLIDES Enterprises FZ LLE ("SLIDES
          Enterprises FZ LLE", "we", "us" or "our"). You should read this Policy
          so you can understand the types of cookies we use, the information we
          collect using cookies and how that information is used. It also
          describes the choices available to you regarding accepting or
          declining the use of cookies.
        </Typography>

        <Typography
          variant="h4"
          className={classes.terms_headings}
          gutterBottom
        >
          What are cookies?
        </Typography>

        <Typography variant="body1" className={classes.terms_text} gutterBottom>
          Cookies are small pieces of data stored in text files that are saved
          on your computer or other devices when websites are loaded in a
          browser. They are widely used to remember you and your preferences,
          either for a single visit (through a "session cookie") or for multiple
          repeat visits (using a "persistent cookie").
        </Typography>
        <Typography variant="body1" className={classes.terms_text} gutterBottom>
          Session cookies are temporary cookies that are used during the course
          of your visit to the Website, and they expire when you close the web
          browser.
        </Typography>
        <Typography variant="body1" className={classes.terms_text} gutterBottom>
          Persistent cookies are used to remember your preferences within our
          Website and remain on your desktop or mobile device even after you
          close your browser or restart your computer. They ensure a consistent
          and efficient experience for you while visiting the Website and
          Services.
        </Typography>
        <Typography variant="body1" className={classes.terms_text} gutterBottom>
          Cookies may be set by the Website ("first-party cookies"), or by third
          parties, such as those who serve content or provide advertising or
          analytics services on the Website ("third party cookies"). These third
          parties can recognize you when you visit our website and also when you
          visit certain other websites.
        </Typography>

        <Typography
          variant="h4"
          className={classes.terms_headings}
          gutterBottom
        >
          What type of cookies do we use?
        </Typography>
        <ul className={classes.terms_text}>
          <li>Necessary cookies</li>
          <p>
            Necessary cookies allow us to offer you the best possible experience
            when accessing and navigating through our Website and using its
            features. For example, these cookies let us recognize that you have
            created an account and have logged into that account to access the
            content.
          </p>
          <li>Functionality cookies</li>
          <p>
            Functionality cookies let us operate the Website and Services in
            accordance with the choices you make. For example, we will recognize
            your username and remember how you customized the Website and
            Services during future visits.
          </p>
          <li>Analytical cookies</li>
          <p>
            These cookies enable us and third party services to collect
            aggregated data for statistical purposes on how our visitors use the
            Website. These cookies do not contain personal information such as
            names and email addresses and are used to help us improve your user
            experience of the Website.
          </p>
          <li>Advertising cookies</li>
          <p>
            Advertising cookies allow us and third parties serve relevant ads to
            you more effectively and help us collect aggregated audit data,
            research, and performance reporting for advertisers. They also
            enable us to understand and improve the delivery of ads to you and
            know when certain ads have been shown to you.
          </p>
          <p>
            Your web browser may request advertisements directly from ad network
            servers, these networks can view, edit, or set their own cookies,
            just as if you had requested a web page from their website.
          </p>
          <p>
            Although we do not use cookies to create a profile of your browsing
            behavior on third party websites, we do use aggregate data from
            third parties to show you relevant, interest-based advertising.
          </p>
          <li>Social media cookies</li>
          <p>
            Third party cookies from social media sites (such as Facebook,
            Twitter, etc) let us track social network users when they visit or
            use the Website and Services, or share content, by using a tagging
            mechanism provided by those social networks.
          </p>
          <p>
            These cookies are also used for event tracking and remarketing
            purposes. Any data collected with these tags will be used in
            accordance with our and social networks’ privacy policies. We will
            not collect or share any personally identifiable information from
            the user.
          </p>
        </ul>
        <Typography
          variant="h4"
          className={classes.terms_headings}
          gutterBottom
        >
          Do we use web beacons or tracking pixels?
        </Typography>
        <Typography variant="body1" className={classes.terms_text} gutterBottom>
          Our emails may contain a "web beacon" (or "tracking pixel") to tell us
          whether our emails are opened and verify any clicks through to links
          or advertisements within the email.
        </Typography>
        <Typography variant="body1" className={classes.terms_text} gutterBottom>
          We may use this information for purposes including determining which
          of our emails are more interesting to users and to query whether users
          who do not open our emails wish to continue receiving them.
        </Typography>
        <Typography variant="body1" className={classes.terms_text} gutterBottom>
          The pixel will be deleted when you delete the email. If you do not
          wish the pixel to be downloaded to your device, you should read the
          email in plain text view or with images disabled.
        </Typography>

        <Typography
          variant="h4"
          className={classes.terms_headings}
          gutterBottom
        >
          What are your cookie options?
        </Typography>
        <Typography variant="body1" className={classes.terms_text} gutterBottom>
          If you don't like the idea of cookies or certain types of cookies, you
          can change your browser's settings to delete cookies that have already
          been set and to not accept new cookies. To learn more about how to do
          this or to learn more about cookies, visit internetcookies.org
        </Typography>
        <Typography variant="body1" className={classes.terms_text} gutterBottom>
          Please note, however, that if you delete cookies or do not accept
          them, you might not be able to use all of the features the Website and
          Services offer.
        </Typography>

        <Typography
          variant="h4"
          className={classes.terms_headings}
          gutterBottom
        >
          Changes and amendments
        </Typography>

        <Typography variant="body1" className={classes.terms_text} gutterBottom>
          We reserve the right to modify this Policy or its terms relating to
          the Website and Services at any time, effective upon posting of an
          updated version of this Policy on the Website. When we do, we will
          revise the updated date at the bottom of this page. Continued use of
          the Website and Services after any such changes shall constitute your
          consent to such changes.
        </Typography>

        <Typography
          variant="h4"
          className={classes.terms_headings}
          gutterBottom
        >
          Acceptance of this policy
        </Typography>
        <Typography variant="body1" className={classes.terms_text} gutterBottom>
          You acknowledge that you have read this Policy and agree to all its
          terms and conditions. By accessing and using the Website and Services
          you agree to be bound by this Policy. If you do not agree to abide by
          the terms of this Policy, you are not authorized to access or use the
          Website and Services.
        </Typography>

        <Typography
          variant="h4"
          className={classes.terms_headings}
          gutterBottom
        >
          Contacting us
        </Typography>
        <Typography variant="body1" className={classes.terms_text} gutterBottom>
          If you would like to contact us to understand more about this Policy
          or wish to contact us concerning any matter relating to our use of
          cookies, you may do so via the contact form
        </Typography>
        <Button
          variant="contained"
          className={classes.reachout}
          onClick={handleClick}
        >
          Reach out
        </Button>
      </Grid>
    </Grid>
  );
};
export default withRouter(CookiePolicy);
