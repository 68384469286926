import React, {useEffect} from 'react';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router-dom';
import { Button, Grid } from '@material-ui/core';

import { useStyles } from './terms-and-conditions';

const Disclaimer=(props)=> {
    const classes = useStyles();
    useEffect(() => {
        if(window.pageYOffset>75){
          document.body.scrollTop=0;
          document.documentElement.scrollTop=0;
        }
        return () => {
          
        }
      }, [])
      const handleClick=()=>{
        props.history.push('/reach-out');
      }
    return (
        <Grid item xs={10} md={9} className={classes.root}>
            <Grid container direction="column">
            <Typography variant="h3" gutterBottom className={classes.terms_title}>Disclaimer</Typography>
            
            <Typography variant="body1" className={classes.terms_text} gutterBottom>This disclaimer ("Disclaimer") sets forth the general guidelines, disclosures, and terms of your use of the "FASHFOOSH" mobile application ("Mobile Application" or "Service") and any of its related products and services (collectively, "Services"). This Disclaimer is a legally binding agreement between you ("User", "you" or "your") and SLIDES Enterprises FZ LLE ("SLIDES Enterprises FZ LLE", "we", "us" or "our"). By accessing and using the Mobile Application and Services, you acknowledge that you have read, understood, and agree to be bound by the terms of this Disclaimer. If you are entering into this Disclaimer on behalf of a business or other legal entity, you represent that you have the authority to bind such entity to this Disclaimer, in which case the terms "User", "you" or "your" shall refer to such entity. If you do not have such authority, or if you do not agree with the terms of this Disclaimer, you must not accept this Disclaimer and may not access and use the Mobile Application and Services. You acknowledge that this Disclaimer is a contract between you and SLIDES Enterprises FZ LLE, even though it is electronic and is not physically signed by you, and it governs your use of the Mobile Application and Services.</Typography>
            
            <Typography variant="h4" className={classes.terms_headings} gutterBottom>Representation</Typography>
            
            <Typography variant="body1" className={classes.terms_text} gutterBottom>Any views or opinions represented in the Mobile Application belong solely to the content creators and do not represent those of people, institutions or organizations that SLIDES Enterprises FZ LLE or creators may or may not be associated with in professional or personal capacity, unless explicitly stated. Any views or opinions are not intended to malign any religion, ethnic group, club, organization, company, or individual.</Typography>

            <Typography variant="h4" className={classes.terms_headings} gutterBottom>Content and postings</Typography>
            <Typography variant="body1" className={classes.terms_text} gutterBottom>You may print a copy of any part of the Mobile Application and Services for your own personal, non-commercial use, but you may not copy any part of the Mobile Application and Services for any other purposes, and you may not modify any part of the Mobile Application and Services. Inclusion of any part of the Mobile Application and Services in another work, whether in printed or electronic or another form or inclusion of any part of the Mobile Application and Services on another resource by embedding, framing or otherwise without the express permission of SLIDES Enterprises FZ LLE is prohibited. </Typography>
            <Typography variant="body1" className={classes.terms_text} gutterBottom>You may submit new content in the Mobile Application. By uploading or otherwise making available any information to SLIDES Enterprises FZ LLE, you grant SLIDES Enterprises FZ LLE the unlimited, perpetual right to distribute, display, publish, reproduce, reuse and copy the information contained therein. You may not impersonate any other person through the Mobile Application and Services. You may not post content that is defamatory, fraudulent, obscene, threatening, invasive of another person's privacy rights or that is otherwise unlawful. You may not post content that infringes on the intellectual property rights of any other person or entity. You may not post any content that includes any computer virus or other code designed to disrupt, damage, or limit the functioning of any computer software or hardware.Compensation and sponsorship</Typography>
            <Typography variant="body1" className={classes.terms_text} gutterBottom>The Mobile Application and Services accepts forms of advertising, sponsorship, paid insertions or other forms of compensation. On certain occasions SLIDES Enterprises FZ LLE may be compensated to provide opinion on products, services, websites and various other topics. Even though SLIDES Enterprises FZ LLE receives compensation for our posts or advertisements, we always give our honest opinions, findings, beliefs, or experiences on those topics or products. The views and opinions expressed in the Mobile Application are purely of SLIDES Enterprises FZ LLE. Any product claim, statistic, quote or other representation about a product or service should be verified with the manufacturer, provider or party in question. Sponsored content, advertising space or post may not always be identified as paid or sponsored content. Some of the links in the Mobile Application may be "affiliate links". This means if you click on the link and purchase an item, SLIDES Enterprises FZ LLE will receive an affiliate commission.Fitness and medical disclaimer</Typography>
            <Typography variant="body1" className={classes.terms_text} gutterBottom>The information available in the Mobile Application is for general health information only and is not intended to be a substitute for professional medical advice, diagnosis or treatment. You should not rely exclusively on information provided in the Mobile Application for your health needs. All specific medical questions should be presented to your own health care provider and you should seek medical advice regarding your health and before starting any nutrition, weight loss or any other type of workout program.</Typography>
            <Typography variant="body1" className={classes.terms_text} gutterBottom>If you choose to use the information available in the Mobile Application without prior consultation with and consent of your physician, you are agreeing to accept full responsibility for your decisions and agreeing to hold harmless SLIDES Enterprises FZ LLE, its agents, employees, contractors, and any affiliated companies from any liability with respect to injury or illness to you or your property arising out of or connected with your use of this information.</Typography>
            <Typography variant="body1" className={classes.terms_text} gutterBottom>There may be risks associated with participating in activities presented in the Mobile Application for people in good or poor health or with pre-existing physical or mental health conditions. If you choose to participate in these risks, you do so of your own free will and accord, knowingly and voluntarily assuming all risks associated with such activities.</Typography>
            <Typography variant="body1" className={classes.terms_text} gutterBottom>The results obtained from the information available in the Mobile Application may vary, and will be based on your individual background, physical health, previous experience, capacity, ability to act, motivation and other variables. There are no guarantees concerning the level of success you may experience.Not legal advice</Typography>
            <Typography variant="body1" className={classes.terms_text} gutterBottom>The information provided in the Mobile Application is for general information purposes only and is not an alternative to legal advice from your lawyer, other professional services provider, or expert. It is not intended to provide legal advice or opinions of any kind. You should not act, or refrain from acting, based solely upon the information provided in the Mobile Application without first seeking appropriate legal or other professional advice. If you have any specific questions about any legal matter, you should consult your lawyer, other professional services provider, or expert. You should never delay seeking legal advice, disregard legal advice, or commence or discontinue any legal action because of the information in the Mobile Application.</Typography>
            <Typography variant="body1" className={classes.terms_text} gutterBottom>The information in the Mobile Application is provided for your convenience only. This information may have no evidentiary value and should be checked against official sources before it is used for any purposes. It is your responsibility to determine whether this information is admissible in a given judicial or administrative proceeding and whether there are any other evidentiary or filing requirements. Your use of this information is at your own risk.Not financial advice</Typography>
            <Typography variant="body1" className={classes.terms_text} gutterBottom>The information in the Mobile Application is provided for your convenience only and is not intended to be treated as financial, investment, tax, or other advice. Nothing contained in the Mobile Application constitutes a solicitation, recommendation, endorsement, or offer by SLIDES Enterprises FZ LLE, its agents, employees, contractors, and any affiliated companies to buy or sell any securities or other financial instruments.</Typography>
            <Typography variant="body1" className={classes.terms_text} gutterBottom>All content on this site is the information of a general nature and does not address the circumstances of any particular individual or entity. Nothing in the Mobile Application constitutes professional and/or financial advice, nor does any information in the Mobile Application constitute a comprehensive or complete statement of the matters discussed or the law relating thereto. You alone assume the sole responsibility of evaluating the merits and risks associated with the use of any information or other content in the Mobile Application before making any decisions based on such information. You agree not to hold SLIDES Enterprises FZ LLE, its agents, employees, contractors, and any affiliated companies liable for any possible claim for damages arising from any decision you make based on the information made available to you through the Website.Not investment advice</Typography>
            <Typography variant="body1" className={classes.terms_text} gutterBottom>All investments are highly speculative in nature and involve substantial risk of loss. We encourage everyone to invest very carefully. We also encourage investors to get personal advice from your professional investment advisor and to make independent investigations before acting on information found in the Mobile Application. We do not in any way whatsoever warrant or guarantee the success of any action you take in reliance on statements or information available in the Mobile Application.</Typography>
            <Typography variant="body1" className={classes.terms_text} gutterBottom>Past performance is not necessarily indicative of future results. All investments carry significant risk and all investment decisions of an individual remain the specific responsibility of that individual. There is no guarantee that systems, indicators, or signals will result in profits or that they will not result in full or partial losses. All investors are advised to fully understand all risks associated with any kind of investing they choose to do.Reviews and testimonials</Typography>
            <Typography variant="body1" className={classes.terms_text} gutterBottom>Testimonials are received in various forms through a variety of submission methods. They are individual experiences, reflecting experiences of those who have used the Services in some way or another. However, they are individual results and results do vary. We do not claim that they are typical results that consumers will generally achieve. The testimonials are not necessarily representative of all of those who will use the Services, and SLIDES Enterprises FZ LLE is not responsible for the opinions or comments posted in the Mobile Application, and does not necessarily share them. All opinions expressed are strictly the views of the poster or reviewer.</Typography>
            <Typography variant="body1" className={classes.terms_text} gutterBottom>Some testimonials may have been edited for clarity, or shortened in cases where the original testimonial included extraneous information of no relevance to the general public. Testimonials may be reviewed for authenticity before they are posted for public viewing.Indemnification and warranties</Typography>
            <Typography variant="body1" className={classes.terms_text} gutterBottom>While we have made every attempt to ensure that the information contained in the Mobile Application is correct, SLIDES Enterprises FZ LLE is not responsible for any errors or omissions, or for the results obtained from the use of this information. All information in the Mobile Application is provided "as is", with no guarantee of completeness, accuracy, timeliness or of the results obtained from the use of this information, and without warranty of any kind, express or implied. In no event will SLIDES Enterprises FZ LLE, or its partners, employees or agents, be liable to you or anyone else for any decision made or action taken in reliance on the information in the Mobile Application, or for any consequential, special or similar damages, even if advised of the possibility of such damages.</Typography>
            <Typography variant="body1" className={classes.terms_text} gutterBottom>As with any business, your results may vary and will be based on your individual capacity, experience, expertise, and level of desire. There are no guarantees concerning the level of success you may experience. There is no guarantee that you will make any income at all and you accept the risk that the earnings and income statements differ by individual. Each individual’s success depends on his or her background, dedication, desire and motivation. The use of the information in the Mobile Application and Services should be based on your own due diligence and you agree that SLIDES Enterprises FZ LLE is not liable for any success or failure of your business that is directly or indirectly related to the purchase and use of our information, products, and services reviewed or advertised in the Mobile Application. Furthermore, information contained in the Mobile Application and any pages linked to and from it are subject to change at any time and without warning.</Typography>
           
            <Typography variant="h4" className={classes.terms_headings} gutterBottom>Changes and amendments</Typography>
            <Typography variant="body1" className={classes.terms_text} gutterBottom>We reserve the right to modify this Disclaimer or its terms relating to the Mobile Application and Services at any time, effective upon posting of an updated version of this Disclaimer in the Mobile Application. When we do, we will revise the updated date at the bottom of this page. Continued use of the Mobile Application and Services after any such changes shall constitute your consent to such changes.</Typography>
            
            <Typography variant="h4" className={classes.terms_headings} gutterBottom>Acceptance of this disclaimer</Typography>
            <Typography variant="body1" className={classes.terms_text} gutterBottom>You acknowledge that you have read this Disclaimer and agree to all its terms and conditions. By accessing and using the Mobile Application and Services you agree to be bound by this Disclaimer. If you do not agree to abide by the terms of this Disclaimer, you are not authorized to access or use the Mobile Application and Services.</Typography>
            
            <Typography variant="h4" className={classes.terms_headings} gutterBottom>Contacting us</Typography>
            <Typography variant="body1" className={classes.terms_text} gutterBottom>If you would like to contact us to understand more about this Disclaimer or wish to contact us concerning any matter relating to it, you may do so via the contact form</Typography>
            <Button variant="contained" className={classes.reachout} onClick={handleClick}>Reach out</Button>
        </Grid>
    </Grid>
    );
}
export default withRouter(Disclaimer);;