import React from "react";
import { Container, Grid, Button, Typography } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import google from "../assets/google.png";
import apple from "../assets/apple.png";
import AboutIcon from "../SVGComponents/AboutIcon";
import PlayIcon from "../SVGComponents/PlayIcon";
import HandbookIcon from "../SVGComponents/HandbookIcon";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { red } from "@material-ui/core/colors";
import Phone1 from "../assets/fishes.png";
import Phone2 from "../assets/market.png";

const useStyles = makeStyles((theme) => ({
  landing_page: {
    zIndex: 10,
  },
  cards: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#189199bd",
    padding: "2rem",
    justifyContent: "center",
    fontFamily: "Poppins",
    height: "30rem",
    borderRadius: "1rem",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "2rem",
      height: "24rem",
    },
  },
  cards_details: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#189199bd",
    padding: "2rem",
    justifyContent: "center",
    fontFamily: "Poppins",
    borderRadius: "1rem",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "2rem",
    },
  },
  landing_cards: {
    marginBottom: "1rem",
    marginTop: "4rem",
    width: "85%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  landing_cards_details: {
    marginBottom: "4rem",
    width: "85%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  card_icons: {
    flexBasis: "2rem",
  },
  landing_card_text: {
    fontWeight: "200",
    color: "white",
  },
  landing_card_text_arabic: {
    fontWeight: "200",
    color: "white",
    fontSize: "1.4rem !important",
  },
  landing_card_heading: {
    fontWeight: "500",
    color: "white",
    marginTop: "1rem",
    textAlign: "center",
  },
  google_play: {
    width: "229px",
    height: "71px",
    marginBottom: "1rem",
    [theme.breakpoints.down("xs")]: {
      width: "184px",
      height: "57px",
    },
  },
  apple_store: {
    width: "229px",
    height: "80px",
    [theme.breakpoints.down("xs")]: {
      width: "187px",
      height: "60px",
    },
  },
  buttons_container: {
    order: 1,
    [theme.breakpoints.down("xs")]: {
      order: 2,
    },
  },
  phone_container: {
    order: 2,
    [theme.breakpoints.down("xs")]: {
      order: 1,
    },
  },
  phone_frame1: {
    height: "585px",
    [theme.breakpoints.down("xs")]: {
      width: "244px",
      height: "430px",
    },
  },
  phone_frame2: {
    height: "585px",
    paddingTop: "5rem",
    [theme.breakpoints.down("xs")]: {
      width: "244px",
      height: "430px",
      paddingTop: "0rem",
    },
  },
  register: {
    background: "white",
    padding: "0.5rem 1rem",
    marginTop: "1rem",
    color: "#19777d",
    "&:hover": {
      background: "white",
    },
  },
}));

const LandingPage = (props) => {
  const classes = useStyles();
  return (
    <>
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.landing_page}
      >
        <Grid item xs={12} md={3} className={classes.buttons_container}>
          <Grid
            container
            justify="center"
            alignItems="center"
            direction="column"
          >
            <Grid item>
              <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.slidesme.fashfoosh"
              >
                <img
                  src={google}
                  className={classes.google_play}
                  style={{ margin: 0 }}
                />
              </a>
            </Grid>
            <Grid item>
              <a
                target="_blank"
                href="https://apps.apple.com/us/app/fashfoosh/id1541802963"
              >
                <img
                  src={apple}
                  className={classes.apple_store}
                  style={{ margin: 0 }}
                />
              </a>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={2}
          container
          justify="center"
          className={classes.phone_container}
        >
          <img src={Phone1} className={classes.phone_frame1} />
        </Grid>
        <Grid
          item
          xs={12}
          md={2}
          container
          justify="center"
          className={classes.phone_container}
        >
          <img src={Phone2} className={classes.phone_frame2} />
        </Grid>
      </Grid>
      <Grid
        container
        justify="space-around"
        alignItems="flex-start"
        className={classes.landing_cards}
      >
        <Grid item xs={9} md={3} className={classes.cards}>
          <div>
            <AboutIcon />
          </div>
          <Typography
            variant="h5"
            className={classes.landing_card_heading}
            gutterBottom
          >
            Fish Seller Registration <br />
            تسجيل أصحاب محلات بيع الأسماك
          </Typography>
          <Button
            className={classes.register}
            onClick={() => props.history.push("/seller-registration")}
            style={{ cursor: "pointer" }}
          >
            Register
          </Button>
        </Grid>

        <Grid item xs={9} md={3} className={classes.cards}>
          <div className={classes.card_icons}>
            <PlayIcon />
          </div>
          <Typography
            variant="h5"
            className={classes.landing_card_heading}
            gutterBottom
          >
            Market Videos
          </Typography>
          <Typography
            variant="body1"
            className={classes.landing_card_text}
            gutterBottom
          >
            Check out videos uploaded by fish sellers from your nearest fish
            market to know about the daily fish prices, place an order and view
            the location and contact details of the fisherman.
          </Typography>
        </Grid>

        <Grid item xs={9} md={3} className={classes.cards}>
          <div className={classes.card_icons}>
            <HandbookIcon />
          </div>
          <Typography
            variant="h5"
            className={classes.landing_card_heading}
            gutterBottom
          >
            Seasonal Fish Handbook
          </Typography>
          <Typography
            variant="body1"
            className={classes.landing_card_text}
            gutterBottom
          >
            A comprehensive picture encyclopedia that offers superb coverage of
            the marine species available in the region. It contains a detailed
            description of the fish in both Arabic and English and covers its
            average size, season periods and the catching and banning dates
            according to authority regulation.
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        justify="space-around"
        alignItems="flex-start"
        className={classes.landing_cards_details}
      >
        <Grid item xs={9} md={3}></Grid>

        <Grid item xs={9} md={7} className={classes.cards_details}>
          <Typography
            variant="body1"
            className={classes.landing_card_text_arabic}
            gutterBottom
            dir="rtl"
          >
            منصة سوق أسماك مفتوحة تهدف إلى الجمع بين الصيادين وعملائهم
            وأولموسوعة للأسماك المحلية.
            <br />
            تتيح المنصة لبائعي الأسماك تحميل مقاطع فيديو يومية لإمدادات الأسماك
            الخاصة بهم، فضلاً عن تعريف مواقعهم ووسائل التواصل بهم وآخر أخبار سوق
            الأسماك لحظة بلحظة والأسعار المتاحة. يمكن للعملاء مشاهدة الأسماك
            المتوفرة في أقرب سوق لهم والطلب منها وتلقي إشعارات بشأن الأسماك
            الموسمية.
            <br />
            يتضمن التطبيق أيضًا موسوعة خاصه حول الأسماك المحليةaبالتفاصيل
            الدقيقه وفترات توفرها.
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default withRouter(LandingPage);
