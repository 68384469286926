import React, { useEffect, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import MuiPhoneNumber from "material-ui-phone-number";

import { cyan } from "@material-ui/core/colors";
import CircularProgress from "@material-ui/core/CircularProgress";
import benifitPayImage from "../assets/BenifitPay.png";
import deliveryIcon from "../assets/delivery.png";

import {
  Button,
  Grid,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Switch,
  FormGroup,
  CardMedia,
  Avatar,
  Snackbar,
  Checkbox,
} from "@material-ui/core";

import {
  village_list,
  arabic_village_list,
  combined_village_list,
} from "../utils/country-details";
// import ReCAPTCHA from "react-google-recaptcha";
import ReachOutConfirm from "../components/ReachOutConfirm";
import firebase from "../firebase";
import axios from "axios";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Alert from "@material-ui/lab/Alert";
import SellerRegistrationModal from "../components/SellerRegistrationModal";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";

const OurSwitch = withStyles({
  switchBase: {
    color: cyan[50],
    "&$checked": {
      color: cyan[900],
    },
    "&$checked + $track": {
      backgroundColor: cyan[50],
    },
  },
  checked: {},
  track: {},
})(Switch);

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "white",
    },
    "& label": {
      color: "rgba(255,255,255,0.8)",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
        border: "1px solid",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
      },
    },
  },
})(TextField);
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2rem",
    backgroundColor: "rgba(0,0,0,0.2)",
    marginTop: "2rem",
    borderRadius: "2rem",
  },
  contact_us_form: {
    marginTop: "3rem",
    marginBottom: "11rem",
    zIndex: "10",
  },
  contact_email: {
    marginTop: "1rem",
    marginBottom: "2rem",
    width: "100%",
    color: "white",
  },
  description: {
    width: "100%",
    marginBottom: "2rem",
  },
  contact_heading: {
    width: "100%",
    textTransform: "uppercase",
    // backgroundColor: 'white',
    padding: "1rem",
    color: "white",
    textAlign: "center",
    fontFamily: "Poppins",
    fontWeight: "500",
  },
  contact_us_submit: {
    marginBottom: "2rem",
    color: "white",
    borderColor: "white",
    transition: "all .4s ease-in-out",
    "&:hover": {
      backgroundColor: "#FFF",
      color: "#179699",
      borderColor: "white",
    },
    [theme.breakpoints.down("xs")]: {
      backgroundColor: "#FFF",
      color: "#179699",
    },
  },
  radio_contact_type: {
    color: "white",
    fontFamily: "Poppins",
  },
  radio_reachout: {
    display: "flex",
  },
  select_icon: {
    fill: "white",
  },
  radio_label_text: {
    color: "white",
  },
  captcha_resize: {
    [theme.breakpoints.down("xs")]: {
      transform: "scale(0.73)",
      transformOrigin: "0 0",
    },
  },
  profile_grid: {
    border: "1px solid white",
    marginTop: "1rem",
    marginBottom: "2rem",
    borderRadius: "0.25rem",
  },
  profile_container: {
    display: "flex",
    alignItems: "center",
    padding: "0.7rem",
  },
  arabic_text_margin: {
    marginBottom: "-0.7rem",
  },

  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "#d0ead0",
    border: "2px solid #60d060",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "2rem",
    display: "flex",
    alignItems: "center",
    color: "#4a3f3f",
    justifyContent: "center",
    flexDirection: "column",
    outline: "none",
    padding: "2rem",
  },
  checkIcon: {
    color: "#108c48",
    fontSize: "6rem !important",
    paddingRight: "0.2rem",
  },
  cancelSuccess: {
    color: "red",
    fontSize: "1.5rem !important",
    cursor: "pointer",
    paddingLeft: "1.5rem",
  },
  allTerms: {
    color: "white",
  },
}));
const initFormData = {
  insta_name: "",
  insta_link: "",
  mobile: "",
  mobile_secondary: "",
  shop_name: "",
  has_benefit: false,
  mobile_benefit: "",
  email: "",
  mobile_delivery: "",
  village: "",
  fereej: "",
  location_addr: "",
};
const SellerRegistration = (props) => {
  const classes = useStyles();
  // let captchaRef;
  const [instaName, setInstaName] = useState("");
  const [accountLink, setAccountLink] = useState("");
  const [PhoneNumber2, setNumber2] = useState("");
  const [shopName, setShopName] = useState("");
  const [benefitPayNumber, setBenefitPayNumber] = useState("");
  const [email, setEmail] = useState("");
  const [deliveryNumber, setDeliveryNumber] = useState("");
  const [village, setVillage] = useState("");
  const [fereej, setFereej] = useState("");
  //const [location, setLocation] = useState();
  // const [captcha, setCaptcha] = useState("");
  const [profilePicURL, setProfilePicURL] = useState("");
  const [fullName, setFullName] = useState("");
  const [instaNameChanged, setInstaNameChanged] = useState(false);
  const [fetchingInsta, setFetchingInsta] = useState(false);
  const [formData, setFormData] = useState(initFormData);
  const [snackOpen, setSnackOpen] = useState(false);
  const [deliveryNotes, setDeliveryNotes] = useState("");
  //const [submit, setSubmit] = useState(false);
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCheckChange = (event) => {
    setChecked(event.target.checked);
  };
  useEffect(() => {
    if (window.pageYOffset > 75) {
      document.body.scrollTop = 0;
    }
    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition(success, error);
    // }
    document.documentElement.scrollTop = 0;
    return () => {};
  }, []);

  // const success = (position) => {
  //   const latitude = position.coords.latitude;
  //   const longitude = position.coords.longitude;
  //   setLocation(new firebase.firestore.GeoPoint(latitude, longitude));
  // };

  // const error = () => {
  //   alert("Unable to retrieve location.");
  // };

  const modifyPhoneNumber = (phoneNumber) => {
    return phoneNumber.split("-").join("").split(" ").join("");
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    // if (captcha === "") {
    //   alert("Please check the recaptcha");
    //   return;
    // }

    if (PhoneNumber2.length <= 4) {
      alert("Please enter your Primary Number.");
      return;
    }
    if (benefitPayNumber.length <= 4) {
      alert("Please enter your BenefitPay Number.");
      return;
    }
    if (deliveryNumber.length <= 4) {
      alert("Please enter your Delivery Number.");
      return;
    }
    setSnackOpen(true);
    setLoading(true);
    sendMail();
    setFormData({
      insta_name: instaName,
      insta_link: accountLink,
      mobile_primary: modifyPhoneNumber(PhoneNumber2),
      shop_name: shopName,
      mobile_benefit: modifyPhoneNumber(benefitPayNumber),
      email: email,
      mobile_delivery: modifyPhoneNumber(deliveryNumber),
      village: village,
      fereej: fereej,
      delivery_notes: deliveryNotes,
    });
    confirmAndSend();
  };

  const sendMail = async () => {
    await firebase
      .firestore()
      .collection("mail")
      .add({
        to: "devops@slidesme.com",
        message: {
          subject: `Fashfoosh Seller ${shopName}`,
          html: `<div style="color:black"><h3 style='display:inline-block;'>Insta Name:</h3>&nbsp;<h4 style='display:inline-block;'>${instaName}</h4><br/>
          <h3 style='display:inline-block;'>Insta Link:</h3>&nbsp;<h4 style='display:inline-block;'>${accountLink}</h4><br/>
          <h3 style='display:inline-block;'>Mobile:</h3>&nbsp;<h4 style='display:inline-block;'>${modifyPhoneNumber(
            PhoneNumber2
          )}</h4><br/>
          <h3 style='display:inline-block;'>Shop Name:</h3>&nbsp;<h4 style='display:inline-block;'>${shopName}</h4><br/>
          <h3 style='display:inline-block;'>BenefitPay Number:</h3>&nbsp;<h4 style='display:inline-block;'>${modifyPhoneNumber(
            benefitPayNumber
          )}</h4><br/>
          <h3 style='display:inline-block;'>Email:</h3>&nbsp;<h4 style='display:inline-block;'>${email}</h4><br/>
          <h3 style='display:inline-block;'>Delivery Number:</h3>&nbsp;<h4 style='display:inline-block;'>${modifyPhoneNumber(
            deliveryNumber
          )}</h4><br/>
          <h3 style='display:inline-block;'>Village:</h3>&nbsp;<h4 style='display:inline-block;'>${village}</h4><br/>
          <h3 style='display:inline-block;'>Fereej:</h3>&nbsp;<h4 style='display:inline-block;'>${fereej}</h4><br/>
          <h3 style='display:inline-block;'>Delivery Notes:</h3>&nbsp;<h4 style='display:inline-block;'>${deliveryNotes}</h4><br/>
            `,
        },
      });
  };

  const handleChangeInstaName = ({ target: { value } }) => {
    setInstaName(value);
    setAccountLink(`https://www.instagram.com/${value}`);
  };

  const handleBlurInstaName = () => {
    if (accountLink != "") {
      getInstaProfile();
      setInstaNameChanged(true);
    } else {
    }
  };

  const getInstaProfile = async () => {
    setFetchingInsta(true);
    try {
      const {
        data: {
          graphql: {
            user: { full_name, profile_pic_url },
          },
        },
      } = await axios.get(`${accountLink}/?__a=1`);
      if (full_name || profile_pic_url) {
        setProfilePicURL(profile_pic_url);
        setFullName(full_name);
        setFetchingInsta(false);
      }
    } catch (error) {
      setProfilePicURL("");
      setFullName("");
      setAccountLink("");
      setInstaName("");
      setFetchingInsta(false);
    }
  };

  // const onCaptchaChange = (value) => {
  //   setCaptcha(value);
  // };
  const confirmAndSend = async () => {
    try {
      const db = firebase.firestore();

      const sellerDocRef = await db.collection("seller").doc();
      sellerDocRef
        .set({
          insta_name: instaName,
          insta_link: accountLink,
          mobile: modifyPhoneNumber(PhoneNumber2),
          shop_name: shopName,
          mobile_benefit: modifyPhoneNumber(benefitPayNumber),
          email: email,
          mobile_delivery: modifyPhoneNumber(deliveryNumber),
          village: village,
          fereej: fereej,
          // location: location,
          has_delivery: true,
          has_benefit: true,
          active: false,
          delivery_notes: deliveryNotes,
          seller_id: sellerDocRef.id,
          info: "",
          created_at: firebase.firestore.Timestamp.fromDate(new Date()),
          new:true
        })
        .then(() => {
          setLoading(false);
          setInstaName("");
          setAccountLink("");
          setNumber2("");
          setShopName("");
          setBenefitPayNumber("");
          setEmail("");
          setDeliveryNumber("");
          setDeliveryNotes("");
          setFereej("");
          setProfilePicURL("");
          setFullName("");
          // captchaRef.reset();
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
    setTimeout(function () {
      setSnackOpen(false);
    }, 5000); //5 Second delay
  };

  const handleSnackClose = () => {
    setSnackOpen(false);
  };

  // const handleClose = () => {
  //   setSubmit(false);
  // };

  return (
    <>
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.contact_us_form}
      >
        <Grid item xs={10} md={8} className={classes.root}>
          <Typography
            variant="h4"
            className={classes.contact_heading}
            dir="rtl"
            style={{
              textAlign: "start",
            }}
          >
            <span style={{ display: "block" }}>قريبا </span>
            أن تطبيق فشفوش صمم خصيصا لبائعي الأسماك يوفر منصة مجانيه خاصه لكل
            بائع توفر له العديد من الأمتيازات كالإعلان عن أسماكهم منذ
            اللحظةالأةل لوصولها كما وتوفر لهم أدوات تصميم العروض الترويجيه حسب
            رغبتهم والأعلان عنها للزبائن القريبين منهم في نفس المنطقه وتسهيل
            عملية تواصل الزبائن مع مندوبي التوصيل و تنظيم عملية الدفع المزيد.
          </Typography>
        </Grid>
        <Grid item xs={10} md={8} className={classes.root}>
          <form onSubmit={handleSubmit}>
            <Grid container justify="center" alignItems="center">
              <Typography variant="h4" className={classes.contact_heading}>
                Seller Registration
                <br />
                تسجيل البائع
              </Typography>

              <Grid item container xs={9} justify="space-between">
                <Typography
                  variant="body1"
                  className={classes.contact_heading}
                  style={{
                    textAlign: "start",
                    padding: "1rem 0",
                    fontSize: "1.3rem",
                  }}
                  dir="rtl"
                >
                  <span style={{ display: "block" }}>مرحبا</span>
                  أعزائي أصحاب محلات بيع الأسماك والفرشات و الباعة الجائلين
                  يمكنكم فورا ملىء الأستماره للستجيل خلال فترة التسجيل المجاني
                  والأستفادة من خصائص البرنامج وبصورة مجانيه ومحصوره و لبائعة
                  الأسماك فقط.
                </Typography>
              </Grid>
              <Grid
                item
                xs={9}
                container
                justify="flex-end"
                style={{
                  textAlign: "end",
                }}
                className={classes.arabic_text_margin}
              >
                <Typography variant="h5" style={{ color: "white" }}>
                  حسابك على الأنستجرام هو ؟
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <CssTextField
                  label="Instagram Name"
                  variant="outlined"
                  placeholder="dumistanfish"
                  type="text"
                  value={instaName}
                  id="instaName"
                  className={classes.contact_email}
                  onChange={handleChangeInstaName}
                  onBlur={handleBlurInstaName}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid
                item
                xs={9}
                container
                justify="flex-end"
                style={{
                  textAlign: "end",
                }}
                className={classes.arabic_text_margin}
              >
                <Typography variant="h5" style={{ color: "white" }}>
                  وصلة حسابك على الأنستجرام
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <CssTextField
                  label="Account Link"
                  variant="outlined"
                  type="url"
                  value={accountLink}
                  id="accountLink"
                  className={classes.contact_email}
                  disabled
                />
              </Grid>
              <Grid item xs={9}>
                <Typography variant="body1" style={{ color: "white" }}>
                  Instagram Profile:
                </Typography>
              </Grid>
              <Grid item className={classes.profile_grid} xs={9}>
                {fetchingInsta ? (
                  <div className={classes.profile_container}>
                    <CircularProgress
                      size="1.5rem"
                      style={{ color: "white" }}
                    />
                    <Typography
                      variant="body1"
                      style={{ color: "white", paddingLeft: "0.7rem" }}
                    >
                      Fetching Instagram Profile...
                    </Typography>
                  </div>
                ) : (
                  <div className={classes.profile_container}>
                    {profilePicURL || fullName ? (
                      <>
                        <img
                          style={{ borderRadius: "50%" }}
                          src={profilePicURL}
                          height="50"
                          width="auto"
                        />
                        <Typography
                          variant="body1"
                          style={{ color: "white", paddingLeft: "0.7rem" }}
                        >
                          {fullName}
                        </Typography>
                      </>
                    ) : (
                      <Typography variant="body1" style={{ color: "white" }}>
                        No Instagram Profile Found
                      </Typography>
                    )}
                  </div>
                )}
              </Grid>
              {/* <Grid item xs={ 9 }>
                <CssTextField
                  label="Account Link"
                  variant="outlined"
                  type="url"
                  value={ accountLink }
                  id="accountLink"
                  className={ classes.contact_email }
                  disabled
                />
              </Grid> */}
              {/* <Grid item xs={9} container justify="flex-end" style={{
                      textAlign: "end"}}>
                <Typography variant="h6" style={{ color: "white" }}>
                  رقم تلفون الوتس أب الذي تتواصل مع زبائنكم عليه ؟ مهم: يجب أن
                  تكون خدمة الوتس أب مفعله على هذا الرقم
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <MuiPhoneNumber
                  label="Phone number with Whatsapp"
                  variant="outlined"
                  value={PhoneNumber1}
                  className={classes.contact_email}
                  onChange={(value) => {
                    setNumber1(value);
                  }}
                  defaultCountry={"bh"}
                  required
                  countryCodeEditable={false}
                />
              </Grid> */}
              <Grid
                item
                xs={9}
                container
                justify="flex-end"
                style={{
                  textAlign: "start",
                }}
                className={classes.arabic_text_margin}
              >
                <Typography variant="h6" style={{ color: "white" }} dir="rtl">
                  رقم التلفون الذي تستقبل عليه المكالمات؟ مهم جدا: هذا هو نفس
                  الرقم الذي سوف يتم تسجيلك عليه في تطبيق فشفوش للدخول للتطبيق
                  والأستفاده من الخدمات المجانيه, دخولك بهذا الرقم سوف يعطيك
                  كافة الصلاحيات البائع.
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <MuiPhoneNumber
                  label="Enter Primary Phone Number"
                  variant="outlined"
                  placeholder="37233887"
                  value={PhoneNumber2}
                  className={classes.contact_email}
                  onChange={(value) => {
                    setNumber2(value);
                  }}
                  required
                  defaultCountry={"bh"}
                  countryCodeEditable={false}
                />
              </Grid>
              {/* <Grid item xs={9}>
                <MuiPhoneNumber
                label="Enter another phone number"
                variant="outlined"
                value={PhoneNumber2}
                className={classes.contact_email}
                onChange={(value) => {
                  setNumber2(value);
                }}
                defaultCountry={"bh"}
                />
              </Grid> */}
              <Grid
                item
                xs={9}
                container
                justify="flex-end"
                style={{
                  textAlign: "end",
                }}
                className={classes.arabic_text_margin}
              >
                <Typography variant="h5" style={{ color: "white" }}>
                  ما هو أسمك التجاري المعروف به ؟
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <CssTextField
                  label="Shop Name"
                  variant="outlined"
                  placeholder="دمستان للأسماك الطازجه"
                  type="text"
                  value={shopName}
                  id="shopName"
                  className={classes.contact_email}
                  onChange={({ target: { value } }) => {
                    setShopName(value);
                  }}
                  required
                  dir="rtl"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid
                item
                xs={9}
                container
                justify="flex-end"
                style={{
                  textAlign: "end",
                }}
                className={classes.arabic_text_margin}
              >
                <Typography variant="h6" style={{ color: "white" }}>
                  ما هو بريدك الألكتروني – نحثك على أستخدام جوجل جي ميل البرنامج
                  متاح للدخول عبر أيميل جوجل و آبل
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <CssTextField
                  label="Email"
                  variant="outlined"
                  placeholder="dumistanfish@gmail.com"
                  type="email"
                  value={email}
                  id="email"
                  className={classes.contact_email}
                  onChange={({ target: { value } }) => {
                    setEmail(value);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              {/* 
              <Grid
              item
                container
                alignItems="center"
                xs={9}
                style={{ marginBottom: "2rem" }}
                >
              <Avatar src={benifitPayImage} />
              <FormGroup style={{ paddingLeft: "0.5rem" }}>
              <FormControlLabel
              control={
                <OurSwitch
                size="normal"
                checked={benefitPayCheck}
                onChange={({ target: { checked } }) => {
                  setBenefitPayCheck(checked);
                }}
                />
              }
              label={`Do you have a BenefitPay account?*`}
              style={{ color: "white" }}
              required
              />
              </FormGroup>
            </Grid> */}

              <Grid
                item
                xs={9}
                container
                justify="space-between"
                style={{
                  textAlign: "end",
                }}
                className={classes.arabic_text_margin}
              >
                <Typography
                  variant="h6"
                  style={{
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Avatar src={benifitPayImage} />
                  BenefitPay
                </Typography>
                <Typography variant="h6" style={{ color: "white" }}>
                  ما هو رقم البنفت الذي تستقبل عليه الأموال ؟
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <MuiPhoneNumber
                  label="Enter BenefitPay Number"
                  variant="outlined"
                  value={benefitPayNumber}
                  className={classes.contact_email}
                  onChange={(value) => {
                    setBenefitPayNumber(value);
                  }}
                  defaultCountry={"bh"}
                  required
                  inputProps={{
                    placeholder: "37233887",
                  }}
                  countryCodeEditable={false}
                />
              </Grid>
              {/* <Grid item xs={ 9 }>
                <FormGroup>
                <FormControlLabel
                control={
                  <OurSwitch
                  size="normal"
                  checked={ deliveryRequired }
                  onChange={ ( { target: { checked } } ) => {
                    setDeliveryRequired( checked );
                  } }
                  />
                }
                label="Delivery Required*"
                className={ classes.contact_email }
                required
                />
                </FormGroup>
              </Grid> */}
              {/* { deliveryRequired == true ? ( */}
              <Grid
                item
                xs={9}
                container
                justify="space-between"
                style={{
                  textAlign: "end",
                }}
                className={classes.arabic_text_margin}
              >
                <Typography
                  variant="h6"
                  style={{
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Avatar src={deliveryIcon} style={{ paddingRight: "10px" }} />
                  Delivery
                </Typography>
                <Typography variant="h6" style={{ color: "white" }}>
                  خدمات التوصيل
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <MuiPhoneNumber
                  label="Enter delivery phone number"
                  variant="outlined"
                  value={deliveryNumber}
                  className={classes.contact_email}
                  onChange={(value) => {
                    setDeliveryNumber(value);
                  }}
                  defaultCountry={"bh"}
                  required
                  countryCodeEditable={false}
                />
              </Grid>
              <Grid
                item
                xs={9}
                container
                justify="flex-end"
                style={{
                  textAlign: "end",
                }}
                className={classes.arabic_text_margin}
              >
                <Typography variant="h5" style={{ color: "white" }}>
                  الرجاء كتابة نص مقتضب للزبائن بخصوص مناطق التوصيل ورسوم
                  التوصيل أن وجدت
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <CssTextField
                  label="Delivery Notes"
                  variant="outlined"
                  type="text"
                  placeholder="التوصيل
                  مجانا دمستان وضواحيها 
                  1 دينار حتى مدينة عيسى و خط البديع 
                  2 باقي مناطق البحرين
                  "
                  value={deliveryNotes}
                  multiline
                  row={3}
                  id="notes"
                  className={classes.contact_email}
                  onChange={({ target: { value } }) => {
                    setDeliveryNotes(value);
                  }}
                  required
                  dir="rtl"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              {/* ) : (
                ""
              ) } */}

              <Grid
                item
                xs={9}
                container
                justify="flex-end"
                style={{
                  textAlign: "end",
                }}
                className={classes.arabic_text_margin}
              >
                <Typography variant="h5" style={{ color: "white" }}>
                  المنطقة
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Autocomplete
                  id="village"
                  className={classes.contact_email}
                  options={combined_village_list}
                  autoHighlight
                  onChange={(event, value) => {
                    // let index = combined_village_list.indexOf(value);
                    // let villageName = village_list[index];
                    setVillage(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Village"
                      variant="outlined"
                      required
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "الدراز Diraz",
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={9}
                container
                justify="flex-end"
                style={{
                  textAlign: "end",
                }}
                className={classes.arabic_text_margin}
              >
                <Typography variant="h5" style={{ color: "white" }}>
                  الفريج ووصف الموقع
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <CssTextField
                  label="Fereej"
                  variant="outlined"
                  type="text"
                  placeholder="سوق دمستان – وسط السوق الشعبي مقابل برادات نور الزهراء"
                  value={fereej}
                  id="fereej"
                  className={classes.contact_email}
                  onChange={({ target: { value } }) => {
                    setFereej(value);
                  }}
                  required
                  dir="rtl"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              {/* <Grid item xs={9}>
                <CssTextField
                  label="Address"
                  variant="outlined"
                  type="text"
                  value={locationAddress}
                  id="locationAddress"
                  className={classes.contact_email}
                  onChange={({ target: { value } }) => {
                    setLocationAddress(value);
                  }}
                  required
                />
              </Grid> */}
              {/* <Grid item xs={9}>
                <CssTextField
                  label="Message"
                  value={description}
                  variant="outlined"
                  multiline
                  rows={2}
                  rowsMax={4}
                  id="description"
                  className={classes.description}
                  onChange={({ target: { value } }) => {
                    setDescription(value);
                  }}
                  required
                />
              </Grid> */}
              {/* <Grid item xs={9} className={classes.contact_email}>
                <ReCAPTCHA
                  ref={(e) => (captchaRef = e)}
                  sitekey="6LfrcNoZAAAAAG8GaRWgAZU5PR9ZTobVhZXGkoNl"
                  onChange={onCaptchaChange}
                  className={classes.captcha_resize}
                />
              </Grid> */}
              <Grid item xs={9} className={classes.contact_email}>
                <FormControlLabel
                  control={
                    <Checkbox checked={true} name="checked" color="primary" />
                  }
                  className={classes.seller_confirm}
                  label={
                    <div>
                      By Submitting You are Accepting terms and condition (
                      <a
                        className={classes.allTerms}
                        target="_blank"
                        href="https://fashfoosh.com/terms-and-conditions"
                      >
                        Terms and Condition
                      </a>
                      ,{" "}
                      <a
                        className={classes.allTerms}
                        target="_blank"
                        href="https://fashfoosh.com/acceptable-policy"
                      >
                        Acceptable Use Policy{" "}
                      </a>
                      ,{" "}
                      <a
                        className={classes.allTerms}
                        target="_blank"
                        href="https://fashfoosh.com/cookie-policy"
                      >
                        Cookie Policy{" "}
                      </a>
                      ,{" "}
                      <a
                        className={classes.allTerms}
                        target="_blank"
                        href="https://fashfoosh.com/disclaimer"
                      >
                        Disclaimer
                      </a>
                      ,
                      <a
                        className={classes.allTerms}
                        target="_blank"
                        href="https://fashfoosh.com/privacy-policy"
                      >
                        Privacy Policy
                      </a>
                      ,{" "}
                      <a
                        className={classes.allTerms}
                        target="_blank"
                        href="https://fashfoosh.com/refund-policy"
                      >
                        Refund Policy
                      </a>
                      )
                    </div>
                  }
                />
              </Grid>
              <Grid item xs={9} className={classes.contact_email}>
                <Button
                  type="submit"
                  variant="outlined"
                  color="secondary"
                  className={classes.contact_us_submit}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
      {/* {submit ? (
        <SellerRegistrationModal
          handleClose={handleClose}
          formData={formData}
          submitAndSend={confirmAndSend}
        />
      ) : (
        ""
      )} */}
      {/* <Snackbar
        open={snackOpen}
        autoHideDuration={5000}
        onClose={handleSnackClose}
      >
        <Alert onClose={handleSnackClose} severity="success">
          Form Submitted Successfully
        </Alert>
      </Snackbar> */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={snackOpen}
        onClose={handleSnackClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={snackOpen}>
          <div className={classes.paper}>
            {loading ? (
              <CircularProgress />
            ) : (
              <>
                <CheckCircleRoundedIcon className={classes.checkIcon} />
                <h2 className={classes.successText} id="transition-modal-title">
                  Successfully Submitted
                </h2>
              </>
            )}
            {/* <CancelIcon
              className={classes.cancelSuccess}
              onClick={handleSnackClose}
            /> */}
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default SellerRegistration;
