import React, { useEffect } from "react";
import "./App.css";
import { Route, Switch } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import TermsAndConditions from "./components/all-terms/terms-and-conditions";
import ReachOut from "./pages/ReachOut";
import AcceptablePolicy from "./components/all-terms/acceptable-policy";
import CookiePolicy from "./components/all-terms/cookie-policy";
import Disclaimer from "./components/all-terms/disclaimer";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Footer from "./components/Footer";
import Header from "./components/Header";
import ContactUs from "./pages/ContactUs";
import PrivacyPolicy from "./components/all-terms/privacy-policy";
import RefundPolicy from "./components/all-terms/refund-policy";
import SellerRegistration from "./pages/SellerRegistration";
import firebase from "./firebase";
import AllTermsAndCondition from "./components/all-terms/all-terms-and-condition";

const useStyles = makeStyles({
  main_container: {
    // backgroundColor: "steelblue",
    background: "linear-gradient(to bottom,#5EE7EA, #0099AA)",
  },
});

function App() {
  const classes = useStyles();
  useEffect(() => {
    firebase.auth().signInAnonymously();
    return () => {};
  }, []);
  return (
    <Grid
      container
      className={classes.main_container}
      justify="center"
      alignItems="center"
      direction="column"
    >
      <Header />
      <Switch>
        <Route path="/seller-registration" component={SellerRegistration} />
        <Route path="/all-terms" component={AllTermsAndCondition} />
        <Route path="/terms-and-conditions" component={TermsAndConditions} />
        <Route path="/acceptable-policy" component={AcceptablePolicy} />
        <Route path="/cookie-policy" component={CookiePolicy} />
        <Route path="/disclaimer" component={Disclaimer} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route path="/refund-policy" component={RefundPolicy} />
        <Route path="/reach-out" component={ReachOut} />
        <Route path="/contactus" component={ContactUs} />
        <Route path="/" component={LandingPage} />
      </Switch>
      <Footer />
    </Grid>
  );
}

export default App;
