export const country_list = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antigua & Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia & Herzegovina",
  "Botswana",
  "Brazil",
  "British Virgin Islands",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Cape Verde",
  "Cayman Islands",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Congo",
  "Cook Islands",
  "Costa Rica",
  "Cote D Ivoire",
  "Croatia",
  "Cruise Ship",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Polynesia",
  "French West Indies",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kuwait",
  "Kyrgyz Republic",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macau",
  "Macedonia",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Namibia",
  "Nepal",
  "Netherlands",
  "Netherlands Antilles",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Norway",
  "Oman",
  "Pakistan",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Reunion",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Pierre &amp; Miquelon",
  "Samoa",
  "San Marino",
  "Satellite",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "South Africa",
  "South Korea",
  "Spain",
  "Sri Lanka",
  "St Kitts &amp; Nevis",
  "St Lucia",
  "St Vincent",
  "St. Lucia",
  "Sudan",
  "Suriname",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor L'Este",
  "Togo",
  "Tonga",
  "Trinidad &amp; Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks &amp; Caicos",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "Uruguay",
  "Uzbekistan",
  "Venezuela",
  "Vietnam",
  "Virgin Islands (US)",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

export const subject_list = [
  "Terms and conditions",
  "Acceptable use policy",
  "Cookie policy",
  "Disclaimer",
  "Privacy policy",
  "Refund policy",
];

export const village_list = [
  "Abu Saiba",
  "Bu Ashira",
  "Bu Ghazal",
  "Bu Quwah",
  "Bu Kowarah",
  "Umm alHassam",
  "Amwaj",
  "alBahair",
  "Budaiya",
  "alBurhama",
  "Busaiteen",
  "Bilad alQadeem",
  "alJasra",
  "alJuffair",
  "alJanabiyah",
  "Halat Bu Maher",
  "Halat Seltah",
  "alHajar",
  "alHajiyat",
  "Hidd",
  "alHoora",
  "Hoora",
  "Khamis",
  "Diplomatic Area",
  "Diraz",
  "alDur",
  "alDair",
  "Daih",
  "Riffa - East",
  "Riffa alGharby",
  "Reef Island",
  "Zallaq",
  "alSayh",
  "Segaya",
  "alSalmaniya",
  "Sanabis",
  "North",
  "alSuwayfiyah",
  "Seef",
  "Shakhurah",
  "Safreyh",
  "Salihiya",
  "Sakhir",
  "Adliya",
  "alEker",
  "alGhurayfah",
  "alFateh",
  "alQadam",
  "alQurayyah",
  "Qudaibiya",
  "alGuful",
  "alQalah",
  "alLawzi",
  "Mahooz",
  "Malkiya",
  "Muharraq",
  "alMarkh",
  "Bahrain Financial Harbour",
  "Maameer",
  "alMagsha",
  "Manama Center",
  "Nabih Saleh",
  "alNaim",
  "Hamala",
  "Barbar",
  "Bilaj alJazaair",
  "Bani Jamra",
  "Buri",
  "Jeblat Hebshi",
  "Jid alHaj",
  "Jid Ali",
  "Jurdab",
  "Jary alShaikh",
  "Jannusan",
  "Jaww",
  "Hillat alAbed aSaleh",
  "Bahrain Bay",
  "Dar Kulaib",
  "Durrat Al Bahrain",
  "Dilmunia Island",
  "Damistan",
  "Dryar Al Muharraq",
  "Ras Rumman",
  "Riffa Views",
  "Saar",
  "Sitra Abu Alayash",
  "Sitra Um alBaidh",
  "Sitra Mall Area",
  "Sitra alKharijiya",
  "Sitra alQaryah",
  "Sitra Sufala",
  "Sitra Murqoban",
  "Sitra Mahaza",
  "Sitra Wadiyan",
  "Salmabad",
  "Samaheej",
  "Sitra Industrial Area",
  "Sanad",
  "Shahrakkan",
  "Sadad",
  "Tashan",
  "A'ali",
  "Adhari - Abu-Baham",
  "Arad",
  "Askar ALBA",
  "Awali",
  "Galaly",
  "Karranah",
  "Karbabad",
  "Karzakkan",
  "Hamad Town",
  "Khalifa Town",
  "Zayed Town",
  "Salman City alShamliya",
  "Isa Town",
  "Maqabah",
  "Mina Salman",
  "Nuwaidrat",
  "Wadi alSail",
];

export const arabic_village_list = [
  " أبوصيبع",
  " أبوعشيره",
  " أبوغزال",
  " أبوقوه ",
  " أبوكواره",
  " أم الحصم ",
  " أمواج",
  " البحير",
  " البديع",
  " البرهامه",
  " البسيتين",
  " البلاد القديم",
  " الجسره",
  " الجفير",
  " الجنبيه",
  " الحالات بوماهر ",
  " الحالات والسلطة والنعيم",
  " الحجر",
  " الحجيات",
  " الحد",
  " الحوره",
  " الحوره",
  " الخميس",
  " الدبلوماسيه",
  " الدراز",
  " الدور",
  " الدير",
  " الديه",
  " الرفاع الشرقي",
  " الرفاع الغربي",
  " الريف",
  " الزلاق",
  " السايه",
  " السقيه",
  " السلمانيه",
  " السنابس",
  " السهله ",
  " السويفيه",
  " السيف",
  " الشاخوره",
  " الصافريه",
  " الصالحيه",
  " الصخير",
  " العدليه",
  " العكر",
  " الغريفه",
  " الفاتح",
  " القدم",
  " القريه",
  " القضيبيه",
  " القفول",
  " القلعه",
  " اللوزي",
  " الماحوز",
  " المالكيه",
  " المحرق",
  " المرخ",
  " المرفأ المالي ",
  " المعامير",
  " المقشع",
  " المنامة العاصمه ",
  " النبيه صالح",
  " النعيم",
  " الهمله",
  " بابار",
  " بلاج الجزائر",
  " بني جمره ",
  " بوري",
  " جبلة حبسي",
  " جد الحاج",
  " جدعلي",
  " جرداب",
  " جري الشيخ ",
  " جنوسان",
  " جو ",
  " حلة العبد الصالح ",
  " خليج البحرين ",
  " دار كليب",
  " درة البحرين",
  " دلومونيا",
  " دمستان",
  " ديار المحرق",
  " رأس رمان",
  " رفاع فيوز",
  " سار",
  " ستره أبوالعيش",
  " ستره أم البيض",
  " ستره التويوتا",
  " ستره الخارجيه",
  " ستره القريه",
  " ستره سفاله",
  " ستره مرقوبان",
  " ستره مهزه",
  " ستره واديان",
  " سلماباد",
  " سماهيج",
  " سنتره الصناعيه",
  " سند",
  " شهركان",
  " صدد",
  " طشان ",
  " عالي",
  " عذاري - أبوبهام",
  " عراد",
  " عسكر",
  " عوالي",
  " قلالي",
  " كرانه",
  " كرباباد",
  " كرزكان",
  " مدينة حمد",
  " مدينة خليفه",
  " مدينة زايد",
  " مدينة سلمان",
  " مدينة عيسي",
  " مقابه",
  " ميناء سلمان",
  " نويدرات",
  " وادي السيل ",
];

export const combined_village_list = village_list.map((val, i) => {
  return `${val} - ${arabic_village_list[i]}`;
});
