import firebase from "firebase";

const config = {
  apiKey: "AIzaSyAJ7EYG6Ku6HdVLufp7ys5e-95dWOPo37A",
  authDomain: "fashfoosh-40cec.firebaseapp.com",
  databaseURL: "https://fashfoosh-40cec.firebaseio.com",
  projectId: "fashfoosh-40cec",
  storageBucket: "fashfoosh-40cec.appspot.com",
  messagingSenderId: "470570400032",
  appId: "1:470570400032:web:f58330fce1057b940dff45",
  measurementId: "G-9WVH47MV9Z",
};
// Initialize Firebase
firebase.initializeApp(config);

export default firebase;
