import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

const useStyles = makeStyles((theme) => ({
  confirm_reachout_text: {
    fontFamily: "Poppins",
    display: "flex",
    alignItems: "center",
    marginBottom: "0.3rem",
    marginTop: "0.4rem",
  },
  confirm_reachout_text_ans: {
    fontFamily: "Poppins",
  },
  grid_container: {
    width: "90%",
  },
  dialogContainer: {
    background: "#73fcef",
    paddingLeft: "4rem",
    paddingRight: "4rem",
    paddingBottom: "1rem",
    paddingTop: "2rem",
  },
  dialogContainer_actions: {
    background: "#73fcef",
    paddingLeft: "4rem",
    paddingRight: "4rem",
    paddingBottom: "1rem",
  },
  paper: {
    minWidth: "60rem",
    [theme.breakpoints.down("xs")]: {
      minWidth: "37rem",
    },
  },
  editButton: {},
  submitButton: {},
}));

const ReachOutConfirm = ({ handleClose, formData, submitAndSend }) => {
  const classes = useStyles();
  return (
    <div>
      <Grid container className={classes.grid_container}>
        <Grid item xs={12} md={12}>
          <Dialog
            open={true}
            onClose={handleClose}
            classes={{ paper: classes.paper }}
          >
            <Grid container className={classes.dialogContainer}>
              <Grid item xs={12} md={9}>
                <Typography
                  variant="h3"
                  className={classes.confirm_reachout_text}
                >
                  Form Preview
                </Typography>
                <Divider />
                <Typography
                  variant="h5"
                  className={classes.confirm_reachout_text}
                >
                  <FiberManualRecordIcon
                    style={{
                      fontSize: 15,
                      color: "#000",
                      paddingRight: "0.4rem",
                    }}
                  />{" "}
                  Contacting as: {formData.value}
                </Typography>

                <Typography
                  variant="h5"
                  className={classes.confirm_reachout_text}
                >
                  <FiberManualRecordIcon
                    style={{
                      fontSize: 15,
                      color: "#000",
                      paddingRight: "0.4rem",
                    }}
                  />{" "}
                  First Name: {formData.firstName}
                </Typography>

                <Typography
                  variant="h5"
                  className={classes.confirm_reachout_text}
                >
                  <FiberManualRecordIcon
                    style={{
                      fontSize: 15,
                      color: "#000",
                      paddingRight: "0.4rem",
                    }}
                  />{" "}
                  Last Name: {formData.lastName}
                </Typography>

                <Typography
                  variant="h5"
                  className={classes.confirm_reachout_text}
                >
                  <FiberManualRecordIcon
                    style={{
                      fontSize: 15,
                      color: "#000",
                      paddingRight: "0.4rem",
                    }}
                  />{" "}
                  Name of the business: {formData.businessName}
                </Typography>

                <Typography
                  variant="h5"
                  className={classes.confirm_reachout_text}
                >
                  <FiberManualRecordIcon
                    style={{
                      fontSize: 15,
                      color: "#000",
                      paddingRight: "0.4rem",
                    }}
                  />{" "}
                  Website of the business: {formData.websiteName}
                </Typography>

                <Typography
                  variant="h5"
                  className={classes.confirm_reachout_text}
                >
                  <FiberManualRecordIcon
                    style={{
                      fontSize: 15,
                      color: "#000",
                      paddingRight: "0.4rem",
                    }}
                  />{" "}
                  Email: {formData.email}
                </Typography>

                <Typography
                  variant="h5"
                  className={classes.confirm_reachout_text}
                >
                  <FiberManualRecordIcon
                    style={{
                      fontSize: 15,
                      color: "#000",
                      paddingRight: "0.4rem",
                    }}
                  />{" "}
                  Phone number: {formData.PhoneNumber1}
                </Typography>

                <Typography
                  variant="h5"
                  className={classes.confirm_reachout_text}
                >
                  <FiberManualRecordIcon
                    style={{
                      fontSize: 15,
                      color: "#000",
                      paddingRight: "0.4rem",
                    }}
                  />
                  Alternate number: {formData.PhoneNumber2}
                </Typography>

                <Typography
                  variant="h5"
                  className={classes.confirm_reachout_text}
                >
                  <FiberManualRecordIcon
                    style={{
                      fontSize: 15,
                      color: "#000",
                      paddingRight: "0.4rem",
                    }}
                  />
                  Country: {formData.country}
                </Typography>

                <Typography
                  variant="h5"
                  className={classes.confirm_reachout_text}
                >
                  <FiberManualRecordIcon
                    style={{
                      fontSize: 15,
                      color: "#000",
                      paddingRight: "0.4rem",
                    }}
                  />{" "}
                  Subject: {formData.subject}
                </Typography>

                <Typography
                  variant="h5"
                  className={classes.confirm_reachout_text}
                >
                  <FiberManualRecordIcon
                    style={{
                      fontSize: 15,
                      color: "#000",
                      paddingRight: "0.4rem",
                    }}
                  />{" "}
                  Details: {formData.details}
                </Typography>

                <Typography
                  variant="h5"
                  className={classes.confirm_reachout_text}
                >
                  <FiberManualRecordIcon
                    style={{
                      fontSize: 15,
                      color: "#000",
                      paddingRight: "0.4rem",
                    }}
                  />{" "}
                  Purpose: {formData.purpose}
                </Typography>

                <Typography
                  variant="h5"
                  className={classes.confirm_reachout_text}
                >
                  <FiberManualRecordIcon
                    style={{
                      fontSize: 15,
                      color: "#000",
                      paddingRight: "0.4rem",
                    }}
                  />{" "}
                  Purpose Details: {formData.purposeDetails}
                </Typography>
              </Grid>
            </Grid>
            <DialogActions className={classes.dialogContainer_actions}>
              <Button
                onClick={handleClose}
                variant="contained"
                color="secondary"
              >
                Edit
              </Button>
              <Button
                onClick={submitAndSend}
                variant="contained"
                color="primary"
                autoFocus
              >
                Confirm and Send
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
        <Grid item md={2}></Grid>
      </Grid>
    </div>
  );
};

export default ReachOutConfirm;
