import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";

import {
  FormControlLabel,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Checkbox from "@material-ui/core/Checkbox";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

const useStyles = makeStyles((theme) => ({
  confirm_reachout_text: {
    fontFamily: "Poppins",
    display: "flex",
    alignItems: "center",
    marginBottom: "0.3rem",
    marginTop: "0.4rem",
  },
  confirm_reachout_text_ans: {
    fontFamily: "Poppins",
  },
  grid_container: {
    width: "90%",
  },
  dialogContainer: {
    background: "#17ad9e",
    paddingLeft: "4rem",
    paddingRight: "4rem",
    paddingBottom: "1rem",
    paddingTop: "2rem",
    color: "white",
  },
  dialogContainer_actions: {
    background: "#17ad9e",
    paddingLeft: "4rem",
    paddingRight: "4rem",
    paddingBottom: "1rem",
  },
  paper: {
    minWidth: "60rem",
    [theme.breakpoints.down("xs")]: {
      minWidth: "37rem",
    },
  },
  editButton: {},
  submitButton: {},
  seller_confirm: {
    color: "white",
  },
  allTerms: {
    color: "white",
  },
}));

const SellerRegistrationModal = ({ handleClose, formData, submitAndSend }) => {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  return (
    <div>
      <Grid container className={classes.grid_container}>
        <Grid item xs={12} md={12}>
          <Dialog
            open={true}
            onClose={handleClose}
            classes={{ paper: classes.paper }}
          >
            <Grid container className={classes.dialogContainer}>
              <Grid item xs={12} md={9}>
                <Typography
                  variant="h3"
                  className={classes.confirm_reachout_text}
                >
                  Your Details
                </Typography>
                <Divider />
                <Typography
                  variant="h5"
                  className={classes.confirm_reachout_text}
                >
                  <FiberManualRecordIcon
                    style={{
                      fontSize: 15,
                      color: "#fff",
                      paddingRight: "0.4rem",
                    }}
                  />{" "}
                  Instagram Name: {formData.insta_name}
                </Typography>

                <Typography
                  variant="h5"
                  className={classes.confirm_reachout_text}
                >
                  <FiberManualRecordIcon
                    style={{
                      fontSize: 15,
                      color: "#fff",
                      paddingRight: "0.4rem",
                    }}
                  />{" "}
                  Mobile: {formData.mobile_primary}
                </Typography>

                <Typography
                  variant="h5"
                  className={classes.confirm_reachout_text}
                >
                  <FiberManualRecordIcon
                    style={{
                      fontSize: 15,
                      color: "#fff",
                      paddingRight: "0.4rem",
                    }}
                  />{" "}
                  Shop Name: {formData.shop_name}
                </Typography>

                <Typography
                  variant="h5"
                  className={classes.confirm_reachout_text}
                >
                  <FiberManualRecordIcon
                    style={{
                      fontSize: 15,
                      color: "#fff",
                      paddingRight: "0.4rem",
                    }}
                  />
                  Benefit Mobile No: {formData.mobile_benefit}
                </Typography>

                <Typography
                  variant="h5"
                  className={classes.confirm_reachout_text}
                >
                  <FiberManualRecordIcon
                    style={{
                      fontSize: 15,
                      color: "#fff",
                      paddingRight: "0.4rem",
                    }}
                  />{" "}
                  Email: {formData.email}
                </Typography>

                <Typography
                  variant="h5"
                  className={classes.confirm_reachout_text}
                >
                  <FiberManualRecordIcon
                    style={{
                      fontSize: 15,
                      color: "#fff",
                      paddingRight: "0.4rem",
                    }}
                  />{" "}
                  Mobile Delivery: {formData.mobile_delivery}
                </Typography>

                <Typography
                  variant="h5"
                  className={classes.confirm_reachout_text}
                >
                  <FiberManualRecordIcon
                    style={{
                      fontSize: 15,
                      color: "#fff",
                      paddingRight: "0.4rem",
                    }}
                  />
                  Village: {formData.village}
                </Typography>

                <Typography
                  variant="h5"
                  className={classes.confirm_reachout_text}
                >
                  <FiberManualRecordIcon
                    style={{
                      fontSize: 15,
                      color: "#fff",
                      paddingRight: "0.4rem",
                    }}
                  />
                  Fereej: {formData.fereej}
                </Typography>

                {/* <Typography
                  variant="h5"
                  className={classes.confirm_reachout_text}
                >
                  <FiberManualRecordIcon
                    style={{
                      fontSize: 15,
                      color: "#fff",
                      paddingRight: "0.4rem",
                    }}
                  />{" "}
                  Description: {formData.description}
                </Typography> */}
                {/* 
                <Typography
                  variant="h5"
                  className={classes.confirm_reachout_text}
                >
                  <FiberManualRecordIcon
                    style={{
                      fontSize: 15,
                      color: "#fff",
                      paddingRight: "0.4rem",
                    }}
                  />{" "}
                  Location Address: {formData.location_addr}
                </Typography> */}

                {/* <Typography
                  variant="h5"
                  className={classes.confirm_reachout_text}
                >
                  <FiberManualRecordIcon style={{ fontSize: 15, color:'#fff', paddingRight:'0.4rem' }} /> Purpose:{" "}
                  {formData.purpose}
                </Typography>

                <Typography
                  variant="h5"
                  className={classes.confirm_reachout_text}
                >
                  <FiberManualRecordIcon style={{ fontSize: 15, color:'#fff', paddingRight:'0.4rem' }} /> Purpose
                  Details: {formData.purposeDetails}
                </Typography> */}
                <Divider />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={handleChange}
                      name="checked"
                      color="primary"
                    />
                  }
                  className={classes.seller_confirm}
                  label={
                    <div>
                      Accept terms and condition (
                      <a
                        className={classes.allTerms}
                        target="_blank"
                        href="https://fashfoosh.com/terms-and-conditions"
                      >
                        Terms and Condition
                      </a>
                      ,{" "}
                      <a
                        className={classes.allTerms}
                        target="_blank"
                        href="https://fashfoosh.com/acceptable-policy"
                      >
                        Acceptable Use Policy{" "}
                      </a>
                      ,{" "}
                      <a
                        className={classes.allTerms}
                        target="_blank"
                        href="https://fashfoosh.com/cookie-policy"
                      >
                        Cookie Policy{" "}
                      </a>
                      ,{" "}
                      <a
                        className={classes.allTerms}
                        target="_blank"
                        href="https://fashfoosh.com/disclaimer"
                      >
                        Disclaimer
                      </a>
                      ,
                      <a
                        className={classes.allTerms}
                        target="_blank"
                        href="https://fashfoosh.com/privacy-policy"
                      >
                        Privacy Policy
                      </a>
                      ,{" "}
                      <a
                        className={classes.allTerms}
                        target="_blank"
                        href="https://fashfoosh.com/refund-policy"
                      >
                        Refund Policy
                      </a>
                      )
                    </div>
                  }
                />
              </Grid>
            </Grid>
            <DialogActions className={classes.dialogContainer_actions}>
              <Button
                onClick={handleClose}
                variant="contained"
                color="secondary"
                className={classes.editButton}
              >
                Edit
              </Button>
              <Button
                onClick={submitAndSend}
                variant="contained"
                className={classes.submitButton}
                color="primary"
                autoFocus
                disabled={!checked}
              >
                Confirm and Send
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
        <Grid item md={2}></Grid>
      </Grid>
    </div>
  );
};

export default SellerRegistrationModal;
