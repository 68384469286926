import React from "react";

const AboutIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="82.897"
      height="82.897"
      viewBox="0 0 82.897 105.897"
    >
      <path
        id="Path_110"
        data-name="Path 110"
        d="M54.557-89.267a7.748,7.748,0,0,1-2.492,5.785,8.289,8.289,0,0,1-5.963,2.4,7.96,7.96,0,0,1-5.829-2.447,8.063,8.063,0,0,1-2.448-5.918,8.289,8.289,0,0,1,2.4-5.963A7.847,7.847,0,0,1,46.1-97.9a8.168,8.168,0,0,1,6.007,2.447A8.384,8.384,0,0,1,54.557-89.267Zm-5.251-.712a3.2,3.2,0,0,0-1.112-2.047,3.247,3.247,0,0,0-2.181-.8,2.945,2.945,0,0,0-2.225.935,3.17,3.17,0,0,0-.89,2.269,3.09,3.09,0,0,0,.935,2.27,3.09,3.09,0,0,0,2.269.934,2.948,2.948,0,0,0,2.314-1.157A3.3,3.3,0,0,0,49.306-89.979ZM78.053-71.823A12.176,12.176,0,0,1,76.1-65.237a15.09,15.09,0,0,1-5.073,4.984,12.45,12.45,0,0,1-6.5,1.869,12.759,12.759,0,0,1-6.586-1.78,13.686,13.686,0,0,1-4.85-4.806,12.682,12.682,0,0,1-1.825-6.675,13,13,0,0,1,1.78-6.631,13.574,13.574,0,0,1,4.806-4.895A12.682,12.682,0,0,1,64.525-85,13.5,13.5,0,0,1,71.2-83.259,13.467,13.467,0,0,1,76.184-78.5,12.454,12.454,0,0,1,78.053-71.823Zm-8.722-.267a4.449,4.449,0,0,0-1.513-3.426,5,5,0,0,0-3.471-1.38,4.648,4.648,0,0,0-3.56,1.424A4.888,4.888,0,0,0,59.452-72a4.955,4.955,0,0,0,1.38,3.515,4.612,4.612,0,0,0,3.516,1.469,4.774,4.774,0,0,0,3.471-1.513A4.871,4.871,0,0,0,69.331-72.09ZM3.56-30.794A31.509,31.509,0,0,1,7.966-47.081,33.053,33.053,0,0,1,19.847-58.963a31.509,31.509,0,0,1,16.287-4.405,31.745,31.745,0,0,1,16.331,4.405A32.945,32.945,0,0,1,64.392-47.081,31.509,31.509,0,0,1,68.8-30.794a31.509,31.509,0,0,1-4.406,16.287A32.944,32.944,0,0,1,52.465-2.626,31.745,31.745,0,0,1,36.134,1.78,31.509,31.509,0,0,1,19.847-2.626,33.053,33.053,0,0,1,7.966-14.507,31.509,31.509,0,0,1,3.56-30.794Zm46.458-.8a13.227,13.227,0,0,0-1.958-7.031,14.83,14.83,0,0,0-5.206-5.118,13.532,13.532,0,0,0-6.987-1.913A13.857,13.857,0,0,0,28.7-43.744a14.107,14.107,0,0,0-5.118,5.118A13.7,13.7,0,0,0,21.716-31.6a13.943,13.943,0,0,0,1.869,7.076A14,14,0,0,0,28.7-19.358a13.857,13.857,0,0,0,7.164,1.914,13.532,13.532,0,0,0,6.987-1.914A14.708,14.708,0,0,0,48.06-24.52,13.456,13.456,0,0,0,50.018-31.6Z"
        transform="matrix(0.996, 0.087, -0.087, 0.996, -3.391, 97.217)"
        fill="#fff"
      />
    </svg>
  );
};

export default AboutIcon;
