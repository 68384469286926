import { Grid, makeStyles, MenuItem, MenuList } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import Bubbles from "./Bubbles";

const useStyles = makeStyles({
  footer: {
    background: "linear-gradient(to bottom,rgba(0,0,0,0.3), rgba(0,0,0,0.6))",
    boxShadow: "0px 0px 74px 64px rgba(0,0,0,0.3)",
    paddingBottom: "2rem",
  },
  footer_list: {
    color: "white",
  },
  copyright: {
    color: "white",
  },
  footer_links: {
    fontSize: "0.8rem",
  },
});

const Footer = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      className={classes.footer}
      justify="center"
      alignItems="flex-start"
      direction="row"
    >
      <Bubbles height="160" width="160" top="27rem" left="0rem" right="2rem" />
      <Bubbles height="120" width="120" top="200px" left="10rem" />
      <Bubbles height="160" width="170" top="-92px" left="12rem" right="20px" />
      <Bubbles height="50" width="50" top="1rem" right="15rem" />
      <Bubbles height="140" width="140" top="3rem" right="4rem" />
      <Bubbles height="230" width="230" top="40rem" right="20rem" />
      <Bubbles height="100" width="100" top="20rem" right="30rem" />
      <Bubbles height="150" width="150" top="30rem" right="50rem" />
      <Bubbles height="60" width="60" top="2rem" right="60rem" />
      <Bubbles height="100" width="100" top="30rem" right="0rem" />
      <Bubbles height="100" width="100" top="-2rem" left="-2rem" />
      <Grid item md={1}></Grid>
      <Grid item xs={6} md={8}>
        <MenuList className={classes.footer_list}>
          <Grid container>
            <Grid item md={1.5}>
              <MenuItem
                className={classes.footer_links}
                component={Link}
                to={"/terms-and-conditions"}
              >
                Terms and conditions
              </MenuItem>
            </Grid>
            <Grid item md={1.5}>
              <MenuItem
                className={classes.footer_links}
                component={Link}
                to={"/acceptable-policy"}
              >
                Acceptable use policy
              </MenuItem>
            </Grid>
            <Grid item md={1.2}>
              <MenuItem
                className={classes.footer_links}
                component={Link}
                to={"/cookie-policy"}
              >
                Cookie policy
              </MenuItem>
            </Grid>
            <Grid item md={1.2}>
              <MenuItem
                className={classes.footer_links}
                component={Link}
                to={"/disclaimer"}
              >
                Disclaimer
              </MenuItem>
            </Grid>
            <Grid item md={1.2}>
              <MenuItem
                className={classes.footer_links}
                component={Link}
                to={"/privacy-policy"}
              >
                Privacy policy
              </MenuItem>
            </Grid>
            <Grid item md={1.2}>
              <MenuItem
                className={classes.footer_links}
                component={Link}
                to={"/refund-policy"}
              >
                Refund policy
              </MenuItem>
            </Grid>
          </Grid>
        </MenuList>
      </Grid>

      <Grid item xs={4} md={3}>
        <MenuList className={classes.footer_list}>
          <Grid container>
            <Grid item md={1.5}>
              <MenuItem
                className={classes.footer_links}
                component={Link}
                to={"/seller-registration"}
              >
                Seller Registration
              </MenuItem>
            </Grid>

            <Grid item md={1.5}>
              <MenuItem
                className={classes.footer_links}
                component={Link}
                to={"/contactus"}
              >
                Contact Us
              </MenuItem>
            </Grid>
          </Grid>
        </MenuList>
      </Grid>
    </Grid>
  );
};

export default Footer;
