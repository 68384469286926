import React, { useEffect, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import MuiPhoneNumber from "material-ui-phone-number";

import TextField from "@material-ui/core/TextField";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import firebase from "../firebase";
import ReCAPTCHA from "react-google-recaptcha";
import AppSelectCountry from "../components/AppSelectCountry";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "white",
    },
    "& label": {
      color: "rgba(255,255,255,0.8)",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
        border: "1px solid",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
      },
    },
  },
})(TextField);
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2rem",
    backgroundColor: "rgba(0,0,0,0.2)",
    marginTop: "2rem",
    borderRadius: "2rem",
  },
  contact_us_form: {
    marginTop: "3rem",
    marginBottom: "11rem",
    zIndex: "10",
  },
  contact_email: {
    marginTop: "1rem",
    marginBottom: "2rem",
    width: "100%",
  },
  description: {
    width: "100%",
    marginBottom: "2rem",
  },
  contact_heading: {
    width: "100%",
    textTransform: "uppercase",
    // backgroundColor: 'white',
    padding: "1rem",
    color: "white",
    textAlign: "center",
    fontFamily: "Poppins",
    fontWeight: "500",
  },
  contact_us_submit: {
    marginBottom: "2rem",
    color: "white",
    borderColor: "white",
    transition: "all .4s ease-in-out",
    "&:hover": {
      backgroundColor: "#FFF",
      color: "#179699",
      borderColor: "white",
    },
    [theme.breakpoints.down("xs")]: {
      backgroundColor: "#FFF",
      color: "#179699",
    },
  },
  select_icon: {
    fill: "white",
  },
  captcha_resize: {
    [theme.breakpoints.down("xs")]: {
      transform: "scale(0.73)",
      transformOrigin: "0 0",
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ContactUs = () => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [number, setNumber] = useState("");
  const [subject, setSubject] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [country, setCountry] = useState();
  const [open, setOpen] = useState(false);
  const [captcha, setCaptcha] = useState("");

  useEffect(() => {
    if (window.pageYOffset > 75) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }

    return () => {};
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (captcha == "") {
      alert("Please check the recaptcha");
      return;
    }
    const mail = await firebase
      .firestore()
      .collection("mail")
      .add({
        to: "info@slidesme.com",
        message: {
          subject: subject,
          html: `<div style="background:#32768b;color:white"><h3 style='display:inline-block;'>Phone Number:</h3>&nbsp;<h4 style='display:inline-block;'>${number}</h4><br/>
          <h3 style='display:inline-block;'>Country:</h3>&nbsp;<h4 style='display:inline-block;'>${country}</h4><br/>
          <h3 style='display:inline-block;'>Description:</h3>&nbsp;<h4 style='display:inline-block;'>${description}</h4></div>`,
        },
      });
    if (mail) {
      setEmail("");
      setDescription("");
      setNumber("");
      setCountry();
      setSubject("");
      setOpen(true);
    }
  };

  const onCaptchaChange = (value) => {
    setCaptcha(value);
  };

  const onCountryChange = (event, value) => {
    if (value) setCountry(value.label);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.contact_us_form}
      >
        <Grid item xs={10} md={8} className={classes.root}>
          <form onSubmit={handleSubmit}>
            <Grid container justify="center" alignItems="center">
              <Typography variant="h4" className={classes.contact_heading}>
                Contact Us
              </Typography>
              <Grid item xs={11} md={9}>
                <CssTextField
                  label="Enter Your Email"
                  variant="outlined"
                  type="email"
                  value={email}
                  id="email"
                  className={classes.contact_email}
                  onChange={({ target: { value } }) => {
                    setEmail(value);
                  }}
                  required
                />
              </Grid>
              <Grid item xs={11} md={9}>
                <MuiPhoneNumber
                  label="Enter your phone number"
                  variant="outlined"
                  value={number}
                  className={classes.contact_email}
                  onChange={(value) => {
                    setNumber(value);
                  }}
                  defaultCountry={"bh"}
                  required
                />
              </Grid>
              {/* <Grid item xs={11} md={9}>
                <FormControl
                  variant="outlined"
                  className={classes.contact_email}
                >
                  <InputLabel> Subject </InputLabel>
                  <Select
                    label="Subject"
                    id="subject"
                    value={subject}
                    onChange={(event) => {
                      setSubject(event.target.value);
                    }}
                    inputProps={{
                      classes: {
                        icon: classes.select_icon,
                      },
                    }}
                    required
                  >
                    {contactSubjectList.map((subject) => (
                      <MenuItem value={subject}> {subject} </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid> */}
              <Grid item xs={11} md={9}>
                <CssTextField
                  label="Subject"
                  value={subject}
                  variant="outlined"
                  id="subject"
                  className={classes.description}
                  onChange={({ target: { value } }) => {
                    setSubject(value);
                  }}
                  required
                />
              </Grid>
              <Grid item xs={11} md={9}>
                <CssTextField
                  label="Description"
                  value={description}
                  variant="outlined"
                  multiline
                  rows={2}
                  rowsMax={4}
                  id="description"
                  className={classes.description}
                  onChange={({ target: { value } }) => {
                    setDescription(value);
                  }}
                  required
                />
              </Grid>
              <Grid item xs={11} md={9}>
                <AppSelectCountry onCountryChange={onCountryChange} />
              </Grid>
              <Grid item xs={11} md={9} className={classes.contact_email}>
                <ReCAPTCHA
                  sitekey="6LfrcNoZAAAAAG8GaRWgAZU5PR9ZTobVhZXGkoNl"
                  onChange={onCaptchaChange}
                  className={classes.captcha_resize}
                />
              </Grid>

              <Grid item xs={11} md={9}>
                <Button
                  type="submit"
                  variant="outlined"
                  color="secondary"
                  className={classes.contact_us_submit}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
      <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Mail sent successfully
        </Alert>
      </Snackbar>
    </>
  );
};

export default ContactUs;
