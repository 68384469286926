import React from "react";

const PlayIcon = () => {
  return (
    <svg
      id="play-button"
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      viewBox="0 0 76.128 76.128"
    >
      <g id="Group_10" data-name="Group 10">
        <path
          id="Path_98"
          data-name="Path 98"
          d="M38.064,0A38.064,38.064,0,1,0,76.128,38.064,38.026,38.026,0,0,0,38.064,0Zm0,69.8A31.741,31.741,0,1,1,69.8,38.064,31.787,31.787,0,0,1,38.064,69.8Z"
          fill="#fff"
        />
        <path
          id="Path_99"
          data-name="Path 99"
          d="M204.333,139.583l-22.015-15.878c-1.771-1.181-5.018-.932-5.018,2.424v31.912c0,3.34,3,3.946,5.018,2.595l22.03-15.878C206.027,143.079,206.166,141.417,204.333,139.583Zm-20.725,12.149V132.281l13.439,9.726Z"
          transform="translate(-149.754 -103.943)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export default PlayIcon;
